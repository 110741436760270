export * from './AbuseReport';
export * from './AppConfig';
export * from './AppHome';
export * from './AppSearchTile';
export * from './AppSearchTiles';
export * from './AppTeaser';
export * from './AppTeasers';
export * from './AppVendorDetails';
export * from './AuthToken';
export * from './Badge';
export * from './Businesscard';
export * from './BusinesscardConfig';
export * from './CommentVendor';
export * from './Contact';
export * from './Country';
export * from './Email';
export * from './EmailConfirmationStatus';
export * from './Error2';
export * from './Event';
export * from './FirebaseSigninData';
export * from './Image';
export * from './ImageVariant';
export * from './Location';
export * from './LocationExtended';
export * from './ModelError';
export * from './NewPassword';
export * from './OfferingsByCategory';
export * from './OpeningHours';
export * from './OpeningHoursItem';
export * from './PushNotificationNewProduct';
export * from './PushNotificationToken';
export * from './Region';
export * from './SigninData';
export * from './SigninResponse';
export * from './SignupData';
export * from './State';
export * from './UserProfile';
export * from './Vendor';
export * from './VendorComment';
export * from './VendorExtended';
export * from './VendorSort';
export * from './VendorUserData';
export * from './Vendors';
export * from './VoteVendor';

import { AbuseReport } from './AbuseReport';
import { AppConfig } from './AppConfig';
import { AppHome } from './AppHome';
import { AppSearchTile } from './AppSearchTile';
import { AppSearchTiles } from './AppSearchTiles';
import { AppTeaser } from './AppTeaser';
import { AppTeasers } from './AppTeasers';
import { AppVendorDetails } from './AppVendorDetails';
import { AuthToken } from './AuthToken';
import { Badge } from './Badge';
import { Businesscard } from './Businesscard';
import { BusinesscardConfig } from './BusinesscardConfig';
import { CommentVendor } from './CommentVendor';
import { Contact } from './Contact';
import { Country } from './Country';
import { Email } from './Email';
import { EmailConfirmationStatus } from './EmailConfirmationStatus';
import { Error2 } from './Error2';
import { Event } from './Event';
import { FirebaseSigninData } from './FirebaseSigninData';
import { Image } from './Image';
import { ImageVariant } from './ImageVariant';
import { Location } from './Location';
import { LocationExtended } from './LocationExtended';
import { ModelError } from './ModelError';
import { NewPassword } from './NewPassword';
import { OfferingsByCategory } from './OfferingsByCategory';
import { OpeningHours } from './OpeningHours';
import { OpeningHoursItem } from './OpeningHoursItem';
import { PushNotificationNewProduct } from './PushNotificationNewProduct';
import { PushNotificationToken } from './PushNotificationToken';
import { Region } from './Region';
import { SigninData } from './SigninData';
import { SigninResponse } from './SigninResponse';
import { SignupData } from './SignupData';
import { State } from './State';
import { UserProfile } from './UserProfile';
import { Vendor } from './Vendor';
import { VendorComment } from './VendorComment';
import { VendorExtended } from './VendorExtended';
import { VendorSort } from './VendorSort';
import { VendorUserData } from './VendorUserData';
import { Vendors } from './Vendors';
import { VoteVendor } from './VoteVendor';

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

const supportedMediaTypes: { [mediaType: string]: number } = {
  "application/json": Infinity,
  "application/octet-stream": 0
}

                 
let enumsMap: Set<string> = new Set<string>([
    "VendorSort",
]);

let typeMap: {[index: string]: any} = {
    "AbuseReport": AbuseReport,
    "AppConfig": AppConfig,
    "AppHome": AppHome,
    "AppSearchTile": AppSearchTile,
    "AppSearchTiles": AppSearchTiles,
    "AppTeaser": AppTeaser,
    "AppTeasers": AppTeasers,
    "AppVendorDetails": AppVendorDetails,
    "AuthToken": AuthToken,
    "Badge": Badge,
    "Businesscard": Businesscard,
    "BusinesscardConfig": BusinesscardConfig,
    "CommentVendor": CommentVendor,
    "Contact": Contact,
    "Country": Country,
    "Email": Email,
    "EmailConfirmationStatus": EmailConfirmationStatus,
    "Error2": Error2,
    "Event": Event,
    "FirebaseSigninData": FirebaseSigninData,
    "Image": Image,
    "ImageVariant": ImageVariant,
    "Location": Location,
    "LocationExtended": LocationExtended,
    "ModelError": ModelError,
    "NewPassword": NewPassword,
    "OfferingsByCategory": OfferingsByCategory,
    "OpeningHours": OpeningHours,
    "OpeningHoursItem": OpeningHoursItem,
    "PushNotificationNewProduct": PushNotificationNewProduct,
    "PushNotificationToken": PushNotificationToken,
    "Region": Region,
    "SigninData": SigninData,
    "SigninResponse": SigninResponse,
    "SignupData": SignupData,
    "State": State,
    "UserProfile": UserProfile,
    "Vendor": Vendor,
    "VendorComment": VendorComment,
    "VendorExtended": VendorExtended,
    "VendorUserData": VendorUserData,
    "Vendors": Vendors,
    "VoteVendor": VoteVendor,
}

export class ObjectSerializer {
    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if(typeMap[discriminatorType]){
                        return discriminatorType; // use the type given in the discriminator
                    } else {
                        return expectedType; // discriminator did not map to a type
                    }
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string, format: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth()+1
                month = month < 10 ? "0" + month.toString() : month.toString()
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();

                return data.getFullYear() + "-" + month + "-" + day;
            } else {
                return data.toISOString();
            }
        } else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }
            
            // Get the actual type of this object
            type = this.findCorrectType(data, type);

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string, format: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap.has(type)) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.name] = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }


    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    public static normalizeMediaType(mediaType: string | undefined): string | undefined {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }

    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    public static getPreferredMediaType(mediaTypes: Array<string>): string {
        /** According to OAS 3 we should default to json */
        if (!mediaTypes) {
            return "application/json";
        }

        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);
        let selectedMediaType: string | undefined = undefined;
        let selectedRank: number = -Infinity;
        for (const mediaType of normalMediaTypes) {
            if (supportedMediaTypes[mediaType!] > selectedRank) {
                selectedMediaType = mediaType;
                selectedRank = supportedMediaTypes[mediaType!];
            }
        }

        if (selectedMediaType === undefined) {
            throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
        }

        return selectedMediaType!;
    }

    /**
     * Convert data to a string according the given media type
     */
    public static stringify(data: any, mediaType: string): string {
        if (mediaType === "application/json") {
            return JSON.stringify(data);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }

    /**
     * Parse data from a string according to the given media type
     */
    public static parse(rawData: string, mediaType: string | undefined) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }

        if (mediaType === "application/json") {
            return JSON.parse(rawData);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}
