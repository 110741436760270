// TODO: better import syntax?
import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import {Configuration} from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {isCodeInRange} from '../util';

import { GeoPoints } from '../models/GeoPoints';

/**
 * no description
 */
export class GeoApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Search for geo_points on the map. The response will consists either of clusters or of concrete markers/vendors. The example lat/long data in geo_boundaries is a boundary around Vienna.
     * @param zoomLevel Current zoom level on the map
     * @param geoBoundaryTopLeftLatitude Filtering by geo boundaries: top left latitude
     * @param geoBoundaryTopLeftLongitude Filtering by geo boundaries: top left longitude
     * @param geoBoundaryBottomRightLatitude Filtering by geo boundaries: bottom right latitude
     * @param geoBoundaryBottomRightLongitude Filtering by geo boundaries: bottom right longitde
     */
    public async getGeoPoints(zoomLevel: number, geoBoundaryTopLeftLatitude?: number, geoBoundaryTopLeftLongitude?: number, geoBoundaryBottomRightLatitude?: number, geoBoundaryBottomRightLongitude?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'zoomLevel' is not null or undefined
        if (zoomLevel === null || zoomLevel === undefined) {
            throw new RequiredError('Required parameter zoomLevel was null or undefined when calling getGeoPoints.');
        }






        // Path Params
        const localVarPath = '/geo-points';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (zoomLevel !== undefined) {
            requestContext.setQueryParam("zoom_level", ObjectSerializer.serialize(zoomLevel, "number", ""));
        }
        if (geoBoundaryTopLeftLatitude !== undefined) {
            requestContext.setQueryParam("geo_boundary[top_left][latitude]", ObjectSerializer.serialize(geoBoundaryTopLeftLatitude, "number", "float"));
        }
        if (geoBoundaryTopLeftLongitude !== undefined) {
            requestContext.setQueryParam("geo_boundary[top_left][longitude]", ObjectSerializer.serialize(geoBoundaryTopLeftLongitude, "number", "float"));
        }
        if (geoBoundaryBottomRightLatitude !== undefined) {
            requestContext.setQueryParam("geo_boundary[bottom_right][latitude]", ObjectSerializer.serialize(geoBoundaryBottomRightLatitude, "number", "float"));
        }
        if (geoBoundaryBottomRightLongitude !== undefined) {
            requestContext.setQueryParam("geo_boundary[bottom_right][longitude]", ObjectSerializer.serialize(geoBoundaryBottomRightLongitude, "number", "float"));
        }

        // Header Params

        // Form Params


        // Body Params

        // Apply auth methods

        return requestContext;
    }

}

export class GeoApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getGeoPoints
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async getGeoPoints(response: ResponseContext): Promise<GeoPoints > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: GeoPoints = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GeoPoints", ""
            ) as GeoPoints;
            return body;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: GeoPoints = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GeoPoints", ""
            ) as GeoPoints;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

}
