import {ImageUrlProvider} from '@/services/provider/image-url-provider';
import {Image} from 'abhof-internal-api';

// API changed - use NewImageUrlProvider

export default class LegacyImageUrlProvider implements ImageUrlProvider {
    public getImageUrl(image: Image): string
    {
        // return process.env.VUE_APP_LEGACY_IMAGE_URI
        //     + image.hash
        //     + '_'
        //     + image.mode
        //     + '.'
        //     + image.extension;
        return '';
    }

}
