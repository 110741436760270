













import {Component, Prop, Vue} from 'vue-property-decorator';


@Component
export default class VendorRating extends Vue {
    @Prop()
    private apiVendorRating!: number;
}
