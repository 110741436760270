/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class PushNotificationToken {
    /**
    * WonderPush Installation ID
    */
    'installationId': string;
    'isTeaser'?: boolean;
    'isVendor'?: boolean;
    'isProduct'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "installationId",
            "baseName": "installation_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "isTeaser",
            "baseName": "is_teaser",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isVendor",
            "baseName": "is_vendor",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isProduct",
            "baseName": "is_product",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PushNotificationToken.attributeTypeMap;
    }
    
    public constructor() {
    }
}

