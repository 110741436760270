export * from './AllezamProduct';
export * from './AllezamProducts';
export * from './Badge';
export * from './Badges';
export * from './Contact';
export * from './Event';
export * from './Events';
export * from './GeoPoint';
export * from './GeoPoints';
export * from './Image';
export * from './ImageUpload';
export * from './ImageVariant';
export * from './Location';
export * from './ModelError';
export * from './OfferingsByCategories';
export * from './OfferingsByCategory';
export * from './OpeningHours';
export * from './OpeningHoursItem';
export * from './SearchResult';
export * from './SearchResults';
export * from './SigninData';
export * from './Vendor';
export * from './VendorComment';
export * from './VendorComments';
export * from './VendorExtended';
export * from './VendorSort';
export * from './Vendors';

import { AllezamProduct } from './AllezamProduct';
import { AllezamProducts } from './AllezamProducts';
import { Badge } from './Badge';
import { Badges } from './Badges';
import { Contact } from './Contact';
import { Event } from './Event';
import { Events } from './Events';
import { GeoPoint } from './GeoPoint';
import { GeoPoints } from './GeoPoints';
import { Image } from './Image';
import { ImageUpload } from './ImageUpload';
import { ImageVariant } from './ImageVariant';
import { Location } from './Location';
import { ModelError } from './ModelError';
import { OfferingsByCategories } from './OfferingsByCategories';
import { OfferingsByCategory } from './OfferingsByCategory';
import { OpeningHours } from './OpeningHours';
import { OpeningHoursItem } from './OpeningHoursItem';
import { SearchResult } from './SearchResult';
import { SearchResults } from './SearchResults';
import { SigninData } from './SigninData';
import { Vendor } from './Vendor';
import { VendorComment } from './VendorComment';
import { VendorComments } from './VendorComments';
import { VendorExtended } from './VendorExtended';
import { VendorSort } from './VendorSort';
import { Vendors } from './Vendors';

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

const supportedMediaTypes: { [mediaType: string]: number } = {
  "application/json": Infinity,
  "application/octet-stream": 0
}

                 
let enumsMap: Set<string> = new Set<string>([
    "VendorSort",
]);

let typeMap: {[index: string]: any} = {
    "AllezamProduct": AllezamProduct,
    "AllezamProducts": AllezamProducts,
    "Badge": Badge,
    "Badges": Badges,
    "Contact": Contact,
    "Event": Event,
    "Events": Events,
    "GeoPoint": GeoPoint,
    "GeoPoints": GeoPoints,
    "Image": Image,
    "ImageUpload": ImageUpload,
    "ImageVariant": ImageVariant,
    "Location": Location,
    "ModelError": ModelError,
    "OfferingsByCategories": OfferingsByCategories,
    "OfferingsByCategory": OfferingsByCategory,
    "OpeningHours": OpeningHours,
    "OpeningHoursItem": OpeningHoursItem,
    "SearchResult": SearchResult,
    "SearchResults": SearchResults,
    "SigninData": SigninData,
    "Vendor": Vendor,
    "VendorComment": VendorComment,
    "VendorComments": VendorComments,
    "VendorExtended": VendorExtended,
    "Vendors": Vendors,
}

export class ObjectSerializer {
    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if(typeMap[discriminatorType]){
                        return discriminatorType; // use the type given in the discriminator
                    } else {
                        return expectedType; // discriminator did not map to a type
                    }
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string, format: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth()+1
                month = month < 10 ? "0" + month.toString() : month.toString()
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();

                return data.getFullYear() + "-" + month + "-" + day;
            } else {
                return data.toISOString();
            }
        } else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }
            
            // Get the actual type of this object
            type = this.findCorrectType(data, type);

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string, format: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap.has(type)) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.name] = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }


    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    public static normalizeMediaType(mediaType: string | undefined): string | undefined {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }

    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    public static getPreferredMediaType(mediaTypes: Array<string>): string {
        /** According to OAS 3 we should default to json */
        if (!mediaTypes) {
            return "application/json";
        }

        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);
        let selectedMediaType: string | undefined = undefined;
        let selectedRank: number = -Infinity;
        for (const mediaType of normalMediaTypes) {
            if (supportedMediaTypes[mediaType!] > selectedRank) {
                selectedMediaType = mediaType;
                selectedRank = supportedMediaTypes[mediaType!];
            }
        }

        if (selectedMediaType === undefined) {
            throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
        }

        return selectedMediaType!;
    }

    /**
     * Convert data to a string according the given media type
     */
    public static stringify(data: any, mediaType: string): string {
        if (mediaType === "application/json") {
            return JSON.stringify(data);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }

    /**
     * Parse data from a string according to the given media type
     */
    public static parse(rawData: string, mediaType: string | undefined) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }

        if (mediaType === "application/json") {
            return JSON.parse(rawData);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}
