









































import {Component, Prop, Vue} from 'vue-property-decorator';
// eslint-disable-next-line no-unused-vars
import {Event as ApiEvent} from 'abhof-internal-api';
// eslint-disable-next-line no-unused-vars
import {Image} from 'abhof-internal-api';
import LazyImage from '@/components/LazyImage.vue';
import {locale} from '@/common/l10n';
// eslint-disable-next-line no-unused-vars
import {imageService, ViewImage} from '@/services/image-service';

@Component({
    components: {
        LazyImage
    }
})
export default class Events extends Vue {
    @Prop()
    private apiEvents!: ApiEvent[];
    public imageUrl!: string;

    private startDate(time: string): string
    {
        const date = this.createDate(time);

        return `${date.getUTCDate()}. ${this.getMonth(date)}`;
    }

    private endDate(time: string): string
    {
        const date = this.createDate(time);

        return `${date.getUTCDate()}. ${this.getMonth(date)} ${date.getUTCFullYear()}`;
    }

    private createDate(time: string): Date
    {
        return new Date(time);
    }

    private getMonth(date: Date): string
    {
        const options = {month: 'long', timeZone: 'UTC'};
        return date.toLocaleString(locale, options);
    }

    private getImage(image: Image): ViewImage
    {
        return imageService.getImage(image);
    }
}
