




































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {throttle} from 'throttle-debounce';
import VueScrollTo from 'vue-scrollto';
// eslint-disable-next-line no-unused-vars
import {imageService, ViewImage} from '@/services/image-service';
// eslint-disable-next-line no-unused-vars
import {Image} from 'abhof-internal-api';
import {getMediaQueryFor, QueryMode} from '@/common/responsive-helper';
import {vendorDetectionService} from '@/services/vendor-detection-service';

Vue.use(VueScrollTo, {
    offset: -60,
    easing: 'ease-in-out'
});

@Component
export default class Navigation extends Vue {

    @Prop()
    private apiProfileImage!: Image;
    @Prop()
    private showCertificatesLink!: boolean;
    @Prop()
    private showShopLink!: boolean
    private farmLogo: ViewImage|null = null;

    private START_TRANSITION = 40;
    private isHidden = true;
    private isOnLargeScreen = false;
    private isScrolling = false;

    private toggleMenu(): void
    {
        if (this.isOnLargeScreen) {
            return;
        }
        this.isHidden = !this.isHidden;
    }

    get menuItems(): Record<string, string>
    {
        let menuItems: Record<string, string> = {
            '#about': 'Über uns',
            '#contact': 'Kontakt',
            '#products': 'Produkte',
            '#openinghours': 'Öffnungszeiten',
            '#events': 'Events'
        }

        if (this.showCertificatesLink) {
            menuItems['#certificates'] = 'Zertifikate';
        }

        return menuItems;
    }

    /**
     * The link generation will be changed as soon the API provides it.
     * https://digitalsunray.atlassian.net/browse/AH-2092
     */
    get shopLink(): string
    {
        let vendorName = vendorDetectionService.getVendorName();
        let shopLink = process.env.VUE_APP_SHOP_LINK;
        if (vendorName === 'testfarm02') {
            vendorName = 'harborth-wein';
        }

        shopLink = shopLink.replace('!VENDOR!', vendorName);

        return shopLink;
    }

    private mounted(): void
    {
        window.addEventListener('scroll', throttle(100, this.onScroll.bind(this)));
        this.farmLogo = imageService.getImage(this.apiProfileImage);
        // be ware of the screen size
        const mediaQueryList = window.matchMedia(getMediaQueryFor(QueryMode.MIN_WIDTH, 'md'));
        this.isOnLargeScreen = mediaQueryList.matches;
        mediaQueryList.addEventListener('change', (mediaQueryList) => {
            this.isOnLargeScreen = mediaQueryList.matches;
        });
    }

    private onScroll(e: Event): void
    {
        const topOffset = (e.target as HTMLDocument).documentElement.scrollTop;
        this.isScrolling = topOffset > this.START_TRANSITION;
    }
}
