/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Image } from './Image';
import { Location } from './Location';
import { HttpFile } from '../http/http';

/**
* Basic version of the Vendor schema
*/
export class Vendor {
    'id'?: number;
    'name'?: string;
    'description'?: string;
    'rating'?: number;
    'isPartner'?: boolean;
    'location'?: Location;
    'profileImage'?: Image;
    'image'?: Image;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "rating",
            "baseName": "rating",
            "type": "number",
            "format": ""
        },
        {
            "name": "isPartner",
            "baseName": "is_partner",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "Location",
            "format": ""
        },
        {
            "name": "profileImage",
            "baseName": "profile_image",
            "type": "Image",
            "format": ""
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "Image",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Vendor.attributeTypeMap;
    }
    
    public constructor() {
    }
}

