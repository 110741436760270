// TODO: better import syntax?
import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import {Configuration} from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {isCodeInRange} from '../util';

import { AllezamProducts } from '../models/AllezamProducts';

/**
 * no description
 */
export class AllezamApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * This endpoint fetches Allezam products for a given vendor. If there is no shop at the moment for this vendor - the `url` property will be null. Important note on the images: the images are comming from Allezam and there is no cropping mechanism. The only thing that is controllable is the scaling size by providing the `image_width` and `image_height`. The scaled image will best fit into provided size. You also have the original image as variant.
     * Get Allezam products for given vendor
     * @param id Vendor ID
     * @param imageWidth Image width
     * @param imageHeight Image height
     */
    public async getAllezamProducts(id: number, imageWidth?: number, imageHeight?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError('Required parameter id was null or undefined when calling getAllezamProducts.');
        }




        // Path Params
        const localVarPath = '/allezam/vendors/{id}/products'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (imageWidth !== undefined) {
            requestContext.setQueryParam("image_width", ObjectSerializer.serialize(imageWidth, "number", ""));
        }
        if (imageHeight !== undefined) {
            requestContext.setQueryParam("image_height", ObjectSerializer.serialize(imageHeight, "number", ""));
        }

        // Header Params

        // Form Params


        // Body Params

        // Apply auth methods

        return requestContext;
    }

}

export class AllezamApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getAllezamProducts
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async getAllezamProducts(response: ResponseContext): Promise<AllezamProducts > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: AllezamProducts = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "AllezamProducts", ""
            ) as AllezamProducts;
            return body;
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(404, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: AllezamProducts = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "AllezamProducts", ""
            ) as AllezamProducts;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

}
