export enum Devices {
    ANDROID = 1,
    IOS = 2,
    MISC = 3
}
export function detectDevice(userAgent: string): Devices
{
    if (/Android/i.test(userAgent)) {
        return Devices.ANDROID;
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return Devices.IOS;
    } else {
        return Devices.MISC;
    }
}
