
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import ContactLink from '@/components/ContactLink.vue';
import lozad from 'lozad';
// eslint-disable-next-line no-unused-vars
import { VendorExtended, LocationExtended } from 'abhof-internal-api';

@Component({
    components: {
        ContactLink,
        ContactMap: () => import('@/components/ContactMap.vue')
    }
})
export default class Contact extends Vue {
    @Prop()
    private location!: LocationExtended;

    @Prop()
    private vendor!: VendorExtended;

    private isMapVisible = false;
    private availableUrls = [
        'facebookUrl',
        'twitterUrl',
        'instagramUrl',
        'websiteUrl',
        'webshopUrl'
    ];

    $refs!: {
        mapTrigger: HTMLElement
    }

    private mounted(): void
    {
        const observer = lozad(this.$refs.mapTrigger, {
            loaded: () => {
                this.isMapVisible = true;
            }
        });
        observer.observe();
    }
}
