/**
 * AbHof API Documentation
 * Relaunched AbHof API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Vendor } from './Vendor';
import { HttpFile } from '../http/http';

export class GeoPoint {
    /**
    * Is this geo point a cluster of vendors or a concrete marker/vendor.
    */
    'isCluster'?: boolean;
    'clusterSize'?: number;
    'latitude'?: number;
    'longitude'?: number;
    /**
    * If this is not a cluster vendor data is populated.
    */
    'vendor'?: Vendor;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "isCluster",
            "baseName": "is_cluster",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "clusterSize",
            "baseName": "cluster_size",
            "type": "number",
            "format": ""
        },
        {
            "name": "latitude",
            "baseName": "latitude",
            "type": "number",
            "format": "float"
        },
        {
            "name": "longitude",
            "baseName": "longitude",
            "type": "number",
            "format": "float"
        },
        {
            "name": "vendor",
            "baseName": "vendor",
            "type": "Vendor",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GeoPoint.attributeTypeMap;
    }
    
    public constructor() {
    }
}

