import {vendorDetectionService, VendorDetectionService} from '@/services/vendor-detection-service';
import {AllezamApi, AllezamProduct as Products, createConfiguration as publicConfiguration} from 'abhof-public-api';
import {BusinesscardApi, Businesscard, createConfiguration as internalConfiguration} from 'abhof-internal-api';

class ApiService {

    private vendorName: string;

    constructor(
        vendorDetectionService: VendorDetectionService
    )
    {
        this.vendorName = vendorDetectionService.getVendorName();
    }

    public async  getBusinessCard(): Promise<Businesscard>
    {
        const apiConfig = internalConfiguration();
        const businesscardApi = new BusinesscardApi(apiConfig);

        return await businesscardApi.getBusinesscard(this.vendorName);
    }

    public async getProducts(vendorId: number): Promise<Products[]>
    {
        const apiConfig = publicConfiguration();
        const allezamApi = new AllezamApi(apiConfig);
        const response = await allezamApi.getAllezamProducts(vendorId);

        return <Products[]>response.collection;
    }

}

export const apiService = new ApiService(vendorDetectionService);
