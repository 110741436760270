/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Image } from './Image';
import { HttpFile } from '../http/http';

export class AppSearchTile {
    'name'?: string;
    'image'?: Image;
    'isOrganic'?: boolean;
    'offeringCategories'?: Array<number>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "Image",
            "format": ""
        },
        {
            "name": "isOrganic",
            "baseName": "is_organic",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "offeringCategories",
            "baseName": "offering_categories",
            "type": "Array<number>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AppSearchTile.attributeTypeMap;
    }
    
    public constructor() {
    }
}

