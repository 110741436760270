import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'

import { AllezamProduct } from '../models/AllezamProduct';
import { AllezamProducts } from '../models/AllezamProducts';
import { Badge } from '../models/Badge';
import { Badges } from '../models/Badges';
import { Contact } from '../models/Contact';
import { Event } from '../models/Event';
import { Events } from '../models/Events';
import { GeoPoint } from '../models/GeoPoint';
import { GeoPoints } from '../models/GeoPoints';
import { Image } from '../models/Image';
import { ImageUpload } from '../models/ImageUpload';
import { ImageVariant } from '../models/ImageVariant';
import { Location } from '../models/Location';
import { ModelError } from '../models/ModelError';
import { OfferingsByCategories } from '../models/OfferingsByCategories';
import { OfferingsByCategory } from '../models/OfferingsByCategory';
import { OpeningHours } from '../models/OpeningHours';
import { OpeningHoursItem } from '../models/OpeningHoursItem';
import { SearchResult } from '../models/SearchResult';
import { SearchResults } from '../models/SearchResults';
import { SigninData } from '../models/SigninData';
import { Vendor } from '../models/Vendor';
import { VendorComment } from '../models/VendorComment';
import { VendorComments } from '../models/VendorComments';
import { VendorExtended } from '../models/VendorExtended';
import { VendorSort } from '../models/VendorSort';
import { Vendors } from '../models/Vendors';
import { ObservableAllezamApi } from './ObservableAPI';

import { AllezamApiRequestFactory, AllezamApiResponseProcessor} from "../apis/AllezamApi";
export class PromiseAllezamApi {
    private api: ObservableAllezamApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AllezamApiRequestFactory,
        responseProcessor?: AllezamApiResponseProcessor
    ) {
        this.api = new ObservableAllezamApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * This endpoint fetches Allezam products for a given vendor. If there is no shop at the moment for this vendor - the `url` property will be null. Important note on the images: the images are comming from Allezam and there is no cropping mechanism. The only thing that is controllable is the scaling size by providing the `image_width` and `image_height`. The scaled image will best fit into provided size. You also have the original image as variant.
     * Get Allezam products for given vendor
     * @param id Vendor ID
     * @param imageWidth Image width
     * @param imageHeight Image height
     */
    public getAllezamProducts(id: number, imageWidth?: number, imageHeight?: number, _options?: Configuration): Promise<AllezamProducts> {
        const result = this.api.getAllezamProducts(id, imageWidth, imageHeight, _options);
        return result.toPromise();
    }


}



import { ObservableGeoApi } from './ObservableAPI';

import { GeoApiRequestFactory, GeoApiResponseProcessor} from "../apis/GeoApi";
export class PromiseGeoApi {
    private api: ObservableGeoApi

    public constructor(
        configuration: Configuration,
        requestFactory?: GeoApiRequestFactory,
        responseProcessor?: GeoApiResponseProcessor
    ) {
        this.api = new ObservableGeoApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Search for geo_points on the map. The response will consists either of clusters or of concrete markers/vendors. The example lat/long data in geo_boundaries is a boundary around Vienna.
     * @param zoomLevel Current zoom level on the map
     * @param geoBoundaryTopLeftLatitude Filtering by geo boundaries: top left latitude
     * @param geoBoundaryTopLeftLongitude Filtering by geo boundaries: top left longitude
     * @param geoBoundaryBottomRightLatitude Filtering by geo boundaries: bottom right latitude
     * @param geoBoundaryBottomRightLongitude Filtering by geo boundaries: bottom right longitde
     */
    public getGeoPoints(zoomLevel: number, geoBoundaryTopLeftLatitude?: number, geoBoundaryTopLeftLongitude?: number, geoBoundaryBottomRightLatitude?: number, geoBoundaryBottomRightLongitude?: number, _options?: Configuration): Promise<GeoPoints> {
        const result = this.api.getGeoPoints(zoomLevel, geoBoundaryTopLeftLatitude, geoBoundaryTopLeftLongitude, geoBoundaryBottomRightLatitude, geoBoundaryBottomRightLongitude, _options);
        return result.toPromise();
    }


}



import { ObservableSearchApi } from './ObservableAPI';

import { SearchApiRequestFactory, SearchApiResponseProcessor} from "../apis/SearchApi";
export class PromiseSearchApi {
    private api: ObservableSearchApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SearchApiRequestFactory,
        responseProcessor?: SearchApiResponseProcessor
    ) {
        this.api = new ObservableSearchApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param q Search query
     */
    public search(q: string, _options?: Configuration): Promise<SearchResults> {
        const result = this.api.search(q, _options);
        return result.toPromise();
    }


}



import { ObservableVendorApi } from './ObservableAPI';

import { VendorApiRequestFactory, VendorApiResponseProcessor} from "../apis/VendorApi";
export class PromiseVendorApi {
    private api: ObservableVendorApi

    public constructor(
        configuration: Configuration,
        requestFactory?: VendorApiRequestFactory,
        responseProcessor?: VendorApiResponseProcessor
    ) {
        this.api = new ObservableVendorApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get vendor details
     * @param id Vendor ID
     */
    public getVendor(id: number, _options?: Configuration): Promise<VendorExtended> {
        const result = this.api.getVendor(id, _options);
        return result.toPromise();
    }

    /**
     * Returns all vendor badges
     * @param id Vendor ID
     */
    public getVendorBadges(id: number, _options?: Configuration): Promise<Badges> {
        const result = this.api.getVendorBadges(id, _options);
        return result.toPromise();
    }

    /**
     * User comments of a vendor
     * @param id Vendor ID
     */
    public getVendorComments(id: number, _options?: Configuration): Promise<VendorComments> {
        const result = this.api.getVendorComments(id, _options);
        return result.toPromise();
    }

    /**
     * Vendor Contact
     * @param id Vendor ID
     */
    public getVendorContact(id: number, _options?: Configuration): Promise<Contact> {
        const result = this.api.getVendorContact(id, _options);
        return result.toPromise();
    }

    /**
     * Returns current and future events of a vendor.
     * @param id Vendor ID
     */
    public getVendorEvents(id: number, _options?: Configuration): Promise<Events> {
        const result = this.api.getVendorEvents(id, _options);
        return result.toPromise();
    }

    /**
     * Return all vendor offerings by category
     * @param id Vendor ID
     */
    public getVendorOfferingsByCategory(id: number, _options?: Configuration): Promise<OfferingsByCategories> {
        const result = this.api.getVendorOfferingsByCategory(id, _options);
        return result.toPromise();
    }

    /**
     * OpeningHours is a tree structure. The `items` array contains roots (`OpeningHoursItem`'s). Each `OpeningHoursItem` can have multiple children, which on their part can have their own children. Each `OpeningHoursItem` can be restricted to a period of time with start/end dates, where it applies. If a `OpeningHoursItem` does not have start/end dates means that opening hours of this period are overall valid. 
     * Vendors opening hours
     * @param id Vendor ID
     */
    public getVendorOpeningHours(id: number, _options?: Configuration): Promise<OpeningHours> {
        const result = this.api.getVendorOpeningHours(id, _options);
        return result.toPromise();
    }

    /**
     * Get a list of vendors
     * @param q Search query
     * @param isPartner Show partner vendors, non-partners vendors or all
     * @param offerings Search for vendors with these offerings
     * @param sort 
     * @param latitude Use geo position for sorting by distance
     * @param longitude Use geo position for sorting by distance
     * @param page Page number
     * @param size Page size
     */
    public getVendors(q?: string, isPartner?: boolean, offerings?: Array<number>, sort?: VendorSort, latitude?: number, longitude?: number, page?: number, size?: number, _options?: Configuration): Promise<Vendors> {
        const result = this.api.getVendors(q, isPartner, offerings, sort, latitude, longitude, page, size, _options);
        return result.toPromise();
    }


}



