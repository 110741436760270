/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class AppConfig {
    'apiVersion'?: string;
    'appVersion'?: string;
    'clientShouldUpdate'?: boolean;
    'minimumClientVersion'?: string;
    'marketPlaceUrl'?: string;
    'gustoEventsUrl'?: string;
    'blogUrl'?: string;
    'aboutUrl'?: string;
    'feedbackEmail'?: string;
    /**
    * Contains the subject for new mail.
    */
    'feedbackSubject'?: string;
    'userPrivacyPolicyPdf'?: string;
    'vendorPrivacyPolicyPdf'?: string;
    'cookiePolicyUrl'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "apiVersion",
            "baseName": "api_version",
            "type": "string",
            "format": ""
        },
        {
            "name": "appVersion",
            "baseName": "app_version",
            "type": "string",
            "format": ""
        },
        {
            "name": "clientShouldUpdate",
            "baseName": "client_should_update",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "minimumClientVersion",
            "baseName": "minimum_client_version",
            "type": "string",
            "format": ""
        },
        {
            "name": "marketPlaceUrl",
            "baseName": "market_place_url",
            "type": "string",
            "format": "url"
        },
        {
            "name": "gustoEventsUrl",
            "baseName": "gusto_events_url",
            "type": "string",
            "format": "url"
        },
        {
            "name": "blogUrl",
            "baseName": "blog_url",
            "type": "string",
            "format": "url"
        },
        {
            "name": "aboutUrl",
            "baseName": "about_url",
            "type": "string",
            "format": "url"
        },
        {
            "name": "feedbackEmail",
            "baseName": "feedback_email",
            "type": "string",
            "format": "email"
        },
        {
            "name": "feedbackSubject",
            "baseName": "feedback_subject",
            "type": "string",
            "format": ""
        },
        {
            "name": "userPrivacyPolicyPdf",
            "baseName": "user_privacy_policy_pdf",
            "type": "string",
            "format": "url"
        },
        {
            "name": "vendorPrivacyPolicyPdf",
            "baseName": "vendor_privacy_policy_pdf",
            "type": "string",
            "format": "url"
        },
        {
            "name": "cookiePolicyUrl",
            "baseName": "cookie_policy_url",
            "type": "string",
            "format": "url"
        }    ];

    static getAttributeTypeMap() {
        return AppConfig.attributeTypeMap;
    }
    
    public constructor() {
    }
}

