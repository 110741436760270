/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AppTeaser } from './AppTeaser';
import { HttpFile } from '../http/http';

export class AppTeasers {
    'collection'?: Array<AppTeaser>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "collection",
            "baseName": "collection",
            "type": "Array<AppTeaser>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AppTeasers.attributeTypeMap;
    }
    
    public constructor() {
    }
}

