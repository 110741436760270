import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { AllezamProduct } from '../models/AllezamProduct';
import { AllezamProducts } from '../models/AllezamProducts';
import { Badge } from '../models/Badge';
import { Badges } from '../models/Badges';
import { Contact } from '../models/Contact';
import { Event } from '../models/Event';
import { Events } from '../models/Events';
import { GeoPoint } from '../models/GeoPoint';
import { GeoPoints } from '../models/GeoPoints';
import { Image } from '../models/Image';
import { ImageUpload } from '../models/ImageUpload';
import { ImageVariant } from '../models/ImageVariant';
import { Location } from '../models/Location';
import { ModelError } from '../models/ModelError';
import { OfferingsByCategories } from '../models/OfferingsByCategories';
import { OfferingsByCategory } from '../models/OfferingsByCategory';
import { OpeningHours } from '../models/OpeningHours';
import { OpeningHoursItem } from '../models/OpeningHoursItem';
import { SearchResult } from '../models/SearchResult';
import { SearchResults } from '../models/SearchResults';
import { SigninData } from '../models/SigninData';
import { Vendor } from '../models/Vendor';
import { VendorComment } from '../models/VendorComment';
import { VendorComments } from '../models/VendorComments';
import { VendorExtended } from '../models/VendorExtended';
import { VendorSort } from '../models/VendorSort';
import { Vendors } from '../models/Vendors';

import { AllezamApiRequestFactory, AllezamApiResponseProcessor} from "../apis/AllezamApi";
export class ObservableAllezamApi {
    private requestFactory: AllezamApiRequestFactory;
    private responseProcessor: AllezamApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AllezamApiRequestFactory,
        responseProcessor?: AllezamApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AllezamApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AllezamApiResponseProcessor();
    }

    /**
     * This endpoint fetches Allezam products for a given vendor. If there is no shop at the moment for this vendor - the `url` property will be null. Important note on the images: the images are comming from Allezam and there is no cropping mechanism. The only thing that is controllable is the scaling size by providing the `image_width` and `image_height`. The scaled image will best fit into provided size. You also have the original image as variant.
     * Get Allezam products for given vendor
     * @param id Vendor ID
     * @param imageWidth Image width
     * @param imageHeight Image height
     */
    public getAllezamProducts(id: number, imageWidth?: number, imageHeight?: number, _options?: Configuration): Observable<AllezamProducts> {
        const requestContextPromise = this.requestFactory.getAllezamProducts(id, imageWidth, imageHeight, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAllezamProducts(rsp)));
            }));
    }
 
}

import { GeoApiRequestFactory, GeoApiResponseProcessor} from "../apis/GeoApi";
export class ObservableGeoApi {
    private requestFactory: GeoApiRequestFactory;
    private responseProcessor: GeoApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: GeoApiRequestFactory,
        responseProcessor?: GeoApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new GeoApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new GeoApiResponseProcessor();
    }

    /**
     * Search for geo_points on the map. The response will consists either of clusters or of concrete markers/vendors. The example lat/long data in geo_boundaries is a boundary around Vienna.
     * @param zoomLevel Current zoom level on the map
     * @param geoBoundaryTopLeftLatitude Filtering by geo boundaries: top left latitude
     * @param geoBoundaryTopLeftLongitude Filtering by geo boundaries: top left longitude
     * @param geoBoundaryBottomRightLatitude Filtering by geo boundaries: bottom right latitude
     * @param geoBoundaryBottomRightLongitude Filtering by geo boundaries: bottom right longitde
     */
    public getGeoPoints(zoomLevel: number, geoBoundaryTopLeftLatitude?: number, geoBoundaryTopLeftLongitude?: number, geoBoundaryBottomRightLatitude?: number, geoBoundaryBottomRightLongitude?: number, _options?: Configuration): Observable<GeoPoints> {
        const requestContextPromise = this.requestFactory.getGeoPoints(zoomLevel, geoBoundaryTopLeftLatitude, geoBoundaryTopLeftLongitude, geoBoundaryBottomRightLatitude, geoBoundaryBottomRightLongitude, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getGeoPoints(rsp)));
            }));
    }
 
}

import { SearchApiRequestFactory, SearchApiResponseProcessor} from "../apis/SearchApi";
export class ObservableSearchApi {
    private requestFactory: SearchApiRequestFactory;
    private responseProcessor: SearchApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SearchApiRequestFactory,
        responseProcessor?: SearchApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SearchApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SearchApiResponseProcessor();
    }

    /**
     * @param q Search query
     */
    public search(q: string, _options?: Configuration): Observable<SearchResults> {
        const requestContextPromise = this.requestFactory.search(q, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.search(rsp)));
            }));
    }
 
}

import { VendorApiRequestFactory, VendorApiResponseProcessor} from "../apis/VendorApi";
export class ObservableVendorApi {
    private requestFactory: VendorApiRequestFactory;
    private responseProcessor: VendorApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: VendorApiRequestFactory,
        responseProcessor?: VendorApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new VendorApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new VendorApiResponseProcessor();
    }

    /**
     * Get vendor details
     * @param id Vendor ID
     */
    public getVendor(id: number, _options?: Configuration): Observable<VendorExtended> {
        const requestContextPromise = this.requestFactory.getVendor(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVendor(rsp)));
            }));
    }
 
    /**
     * Returns all vendor badges
     * @param id Vendor ID
     */
    public getVendorBadges(id: number, _options?: Configuration): Observable<Badges> {
        const requestContextPromise = this.requestFactory.getVendorBadges(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVendorBadges(rsp)));
            }));
    }
 
    /**
     * User comments of a vendor
     * @param id Vendor ID
     */
    public getVendorComments(id: number, _options?: Configuration): Observable<VendorComments> {
        const requestContextPromise = this.requestFactory.getVendorComments(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVendorComments(rsp)));
            }));
    }
 
    /**
     * Vendor Contact
     * @param id Vendor ID
     */
    public getVendorContact(id: number, _options?: Configuration): Observable<Contact> {
        const requestContextPromise = this.requestFactory.getVendorContact(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVendorContact(rsp)));
            }));
    }
 
    /**
     * Returns current and future events of a vendor.
     * @param id Vendor ID
     */
    public getVendorEvents(id: number, _options?: Configuration): Observable<Events> {
        const requestContextPromise = this.requestFactory.getVendorEvents(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVendorEvents(rsp)));
            }));
    }
 
    /**
     * Return all vendor offerings by category
     * @param id Vendor ID
     */
    public getVendorOfferingsByCategory(id: number, _options?: Configuration): Observable<OfferingsByCategories> {
        const requestContextPromise = this.requestFactory.getVendorOfferingsByCategory(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVendorOfferingsByCategory(rsp)));
            }));
    }
 
    /**
     * OpeningHours is a tree structure. The `items` array contains roots (`OpeningHoursItem`'s). Each `OpeningHoursItem` can have multiple children, which on their part can have their own children. Each `OpeningHoursItem` can be restricted to a period of time with start/end dates, where it applies. If a `OpeningHoursItem` does not have start/end dates means that opening hours of this period are overall valid. 
     * Vendors opening hours
     * @param id Vendor ID
     */
    public getVendorOpeningHours(id: number, _options?: Configuration): Observable<OpeningHours> {
        const requestContextPromise = this.requestFactory.getVendorOpeningHours(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVendorOpeningHours(rsp)));
            }));
    }
 
    /**
     * Get a list of vendors
     * @param q Search query
     * @param isPartner Show partner vendors, non-partners vendors or all
     * @param offerings Search for vendors with these offerings
     * @param sort 
     * @param latitude Use geo position for sorting by distance
     * @param longitude Use geo position for sorting by distance
     * @param page Page number
     * @param size Page size
     */
    public getVendors(q?: string, isPartner?: boolean, offerings?: Array<number>, sort?: VendorSort, latitude?: number, longitude?: number, page?: number, size?: number, _options?: Configuration): Observable<Vendors> {
        const requestContextPromise = this.requestFactory.getVendors(q, isPartner, offerings, sort, latitude, longitude, page, size, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVendors(rsp)));
            }));
    }
 
}
