import {ImageUrlProvider, ImageMode} from '@/services/provider/image-url-provider';
import {Image} from 'abhof-internal-api';

export default class NewImageUrlProvider implements ImageUrlProvider {

    getImageUrl(image: Image, mode?: ImageMode): string {
        if (mode) {
            const imageVariant = image.variants?.find(imageVariant => imageVariant.mode === mode);
            if (typeof imageVariant === 'undefined') {
                throw new Error(`NewImageUrlProvider: ${mode} not found`);
            }

            return imageVariant.url!;
        } else {
            return image.variants![0].url!;
        }
    }


}
