
































import {Component, Prop, Vue} from 'vue-property-decorator';
// eslint-disable-next-line no-unused-vars
import { BusinesscardConfig } from 'abhof-internal-api';


@Component
export default class CookieConsent extends Vue {
    @Prop()
    private config!: BusinesscardConfig;
    private isConsented = false;
    private LOCAL_STORAGE_KEY = 'isCookieConsented';
    private LOCAL_STORAGE_VALUE = '1';

    private created(): void
    {
        this.isConsented = window.localStorage.getItem(this.LOCAL_STORAGE_KEY) !== null;
    }

    private doConsent(): void
    {
        this.isConsented = true;
        window.localStorage.setItem(this.LOCAL_STORAGE_KEY, this.LOCAL_STORAGE_VALUE);
    }
}
