














import {Component, Prop, Vue} from 'vue-property-decorator';
// eslint-disable-next-line no-unused-vars
import { Badge } from 'abhof-public-api';
// eslint-disable-next-line no-unused-vars
import {Image} from 'abhof-internal-api';
// eslint-disable-next-line no-unused-vars
import {imageService, ViewImage} from '@/services/image-service';
import LazyImage from '@/components/LazyImage.vue';


@Component({
    components: {
        LazyImage
    }
})
export default class Certificates extends Vue {
    @Prop()
    private apiBadges!: Badge[];

    private getImage(image: Image): ViewImage
    {
        return imageService.getImage(image);
    }
}
