




































import {Component, Vue, Prop} from 'vue-property-decorator';
import {apiService} from '@/services/api-service';
// eslint-disable-next-line no-unused-vars
import {AllezamProduct as Product} from 'abhof-public-api';
import LazyImage from '@/components/LazyImage.vue';
import {currency, locale} from '@/common/l10n';
// eslint-disable-next-line no-unused-vars
import {Image} from 'abhof-internal-api';
// eslint-disable-next-line no-unused-vars
import {imageService, ViewImage} from '@/services/image-service';
import {detectDevice, Devices} from '@/common/device-detection';
// eslint-disable-next-line no-unused-vars
import { BusinesscardConfig } from 'abhof-internal-api';


@Component({
    components: {
        LazyImage
    }
})
export default class Products extends Vue {
    private products: Product[]|null = null;
    @Prop()
    private apiConfig!: BusinesscardConfig;

    @Prop()
    private apiVendorId!: number;

    private created(): void
    {
        apiService.getProducts(this.apiVendorId)
            .then((products: Product[]) => {
                this.products = products;
                if (products[0].url !== undefined) {
                    this.$emit('show-shop-link');
                }
            })
            .catch(() => {
                this.$emit('hide-section');
            });
    }

    private formatPrice(price: number): string
    {
        const options =  {
            style: 'currency',
            currency: currency
        };
        const floatPrice = price / 100;

        return floatPrice.toLocaleString(locale, options);
    }

    private getLinkLabel(product: Product): string
    {
        if (product.url === undefined) {
            return 'Details in der App';
        } else {
            return 'Zum Produkt';
        }
    }

    private getImage(image: Image): ViewImage
    {
        return imageService.getImage(image);
    }

    private onLinkClick(product: Product): void
    {
        let url: string|undefined;
        if (product.url !== undefined) {
            url = product.url;
        } else {
            const device = detectDevice(navigator.userAgent);
            if (device === Devices.ANDROID) {
                url = this.apiConfig.playStoreUrl;
            } else if (device === Devices.IOS) {
                url = this.apiConfig.appStoreUrl;
            } else {
                url = this.apiConfig.appLandingpageUrl!;
            }
        }

        window.open(url, '_blank');
    }
}
