// TODO: better import syntax?
import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import {Configuration} from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {isCodeInRange} from '../util';

import { CommentVendor } from '../models/CommentVendor';
import { Image } from '../models/Image';
import { VendorComment } from '../models/VendorComment';
import { VoteVendor } from '../models/VoteVendor';

/**
 * no description
 */
export class VendorApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Comment a Vendor as User.
     * @param vendorId 
     * @param commentVendor Vendor
     */
    public async commentVendor(vendorId: number, commentVendor: CommentVendor, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'vendorId' is not null or undefined
        if (vendorId === null || vendorId === undefined) {
            throw new RequiredError('Required parameter vendorId was null or undefined when calling commentVendor.');
        }


        // verify required parameter 'commentVendor' is not null or undefined
        if (commentVendor === null || commentVendor === undefined) {
            throw new RequiredError('Required parameter commentVendor was null or undefined when calling commentVendor.');
        }


        // Path Params
        const localVarPath = '/internal/vendors/{vendorId}/comments'
            .replace('{' + 'vendorId' + '}', encodeURIComponent(String(vendorId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(commentVendor, "CommentVendor", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        // Apply auth methods

        return requestContext;
    }

    /**
     * Delete Vendor Comment Image.
     * @param vendorId 
     * @param commentId 
     * @param imageId 
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public async deleteVendorCommentImage(vendorId: number, commentId: number, imageId: number, file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'vendorId' is not null or undefined
        if (vendorId === null || vendorId === undefined) {
            throw new RequiredError('Required parameter vendorId was null or undefined when calling deleteVendorCommentImage.');
        }


        // verify required parameter 'commentId' is not null or undefined
        if (commentId === null || commentId === undefined) {
            throw new RequiredError('Required parameter commentId was null or undefined when calling deleteVendorCommentImage.');
        }


        // verify required parameter 'imageId' is not null or undefined
        if (imageId === null || imageId === undefined) {
            throw new RequiredError('Required parameter imageId was null or undefined when calling deleteVendorCommentImage.');
        }


        // verify required parameter 'file' is not null or undefined
        if (file === null || file === undefined) {
            throw new RequiredError('Required parameter file was null or undefined when calling deleteVendorCommentImage.');
        }


        // verify required parameter 'cropAreaX' is not null or undefined
        if (cropAreaX === null || cropAreaX === undefined) {
            throw new RequiredError('Required parameter cropAreaX was null or undefined when calling deleteVendorCommentImage.');
        }


        // verify required parameter 'cropAreaY' is not null or undefined
        if (cropAreaY === null || cropAreaY === undefined) {
            throw new RequiredError('Required parameter cropAreaY was null or undefined when calling deleteVendorCommentImage.');
        }


        // verify required parameter 'cropAreaWidth' is not null or undefined
        if (cropAreaWidth === null || cropAreaWidth === undefined) {
            throw new RequiredError('Required parameter cropAreaWidth was null or undefined when calling deleteVendorCommentImage.');
        }


        // verify required parameter 'cropAreaHeight' is not null or undefined
        if (cropAreaHeight === null || cropAreaHeight === undefined) {
            throw new RequiredError('Required parameter cropAreaHeight was null or undefined when calling deleteVendorCommentImage.');
        }


        // Path Params
        const localVarPath = '/internal/vendors/{vendorId}/comments/{commentId}/images/{imageId}'
            .replace('{' + 'vendorId' + '}', encodeURIComponent(String(vendorId)))
            .replace('{' + 'commentId' + '}', encodeURIComponent(String(commentId)))
            .replace('{' + 'imageId' + '}', encodeURIComponent(String(imageId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params
        let localVarFormParams = new FormData();

        if (file !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('file', file, file.name);
        }
        if (cropAreaX !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_x', cropAreaX as any);
        }
        if (cropAreaY !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_y', cropAreaY as any);
        }
        if (cropAreaWidth !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_width', cropAreaWidth as any);
        }
        if (cropAreaHeight !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_height', cropAreaHeight as any);
        }
        requestContext.setBody(localVarFormParams);

        // Body Params

        // Apply auth methods

        return requestContext;
    }

    /**
     * Delete User Vendor Favorite.
     * @param vendorId 
     */
    public async deleteVendorFavorite(vendorId: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'vendorId' is not null or undefined
        if (vendorId === null || vendorId === undefined) {
            throw new RequiredError('Required parameter vendorId was null or undefined when calling deleteVendorFavorite.');
        }


        // Path Params
        const localVarPath = '/internal/vendors/{vendorId}/favorites'
            .replace('{' + 'vendorId' + '}', encodeURIComponent(String(vendorId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params


        // Body Params

        // Apply auth methods

        return requestContext;
    }

    /**
     * Favor a Vendor as User.
     * @param vendorId 
     */
    public async favorVendor(vendorId: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'vendorId' is not null or undefined
        if (vendorId === null || vendorId === undefined) {
            throw new RequiredError('Required parameter vendorId was null or undefined when calling favorVendor.');
        }


        // Path Params
        const localVarPath = '/internal/vendors/{vendorId}/favorites'
            .replace('{' + 'vendorId' + '}', encodeURIComponent(String(vendorId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params


        // Body Params

        // Apply auth methods

        return requestContext;
    }

    /**
     * Upload an Image for a Vendor Comment.
     * @param vendorId 
     * @param commentId 
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public async uploadVendorCommentImage(vendorId: number, commentId: number, file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'vendorId' is not null or undefined
        if (vendorId === null || vendorId === undefined) {
            throw new RequiredError('Required parameter vendorId was null or undefined when calling uploadVendorCommentImage.');
        }


        // verify required parameter 'commentId' is not null or undefined
        if (commentId === null || commentId === undefined) {
            throw new RequiredError('Required parameter commentId was null or undefined when calling uploadVendorCommentImage.');
        }


        // verify required parameter 'file' is not null or undefined
        if (file === null || file === undefined) {
            throw new RequiredError('Required parameter file was null or undefined when calling uploadVendorCommentImage.');
        }


        // verify required parameter 'cropAreaX' is not null or undefined
        if (cropAreaX === null || cropAreaX === undefined) {
            throw new RequiredError('Required parameter cropAreaX was null or undefined when calling uploadVendorCommentImage.');
        }


        // verify required parameter 'cropAreaY' is not null or undefined
        if (cropAreaY === null || cropAreaY === undefined) {
            throw new RequiredError('Required parameter cropAreaY was null or undefined when calling uploadVendorCommentImage.');
        }


        // verify required parameter 'cropAreaWidth' is not null or undefined
        if (cropAreaWidth === null || cropAreaWidth === undefined) {
            throw new RequiredError('Required parameter cropAreaWidth was null or undefined when calling uploadVendorCommentImage.');
        }


        // verify required parameter 'cropAreaHeight' is not null or undefined
        if (cropAreaHeight === null || cropAreaHeight === undefined) {
            throw new RequiredError('Required parameter cropAreaHeight was null or undefined when calling uploadVendorCommentImage.');
        }


        // Path Params
        const localVarPath = '/internal/vendors/{vendorId}/comments/{commentId}/images'
            .replace('{' + 'vendorId' + '}', encodeURIComponent(String(vendorId)))
            .replace('{' + 'commentId' + '}', encodeURIComponent(String(commentId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params
        let localVarFormParams = new FormData();

        if (file !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('file', file, file.name);
        }
        if (cropAreaX !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_x', cropAreaX as any);
        }
        if (cropAreaY !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_y', cropAreaY as any);
        }
        if (cropAreaWidth !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_width', cropAreaWidth as any);
        }
        if (cropAreaHeight !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_height', cropAreaHeight as any);
        }
        requestContext.setBody(localVarFormParams);

        // Body Params

        // Apply auth methods

        return requestContext;
    }

    /**
     * Rate a Vendor as User.
     * @param vendorId 
     * @param voteVendor Vendor
     */
    public async voteVendor(vendorId: number, voteVendor: VoteVendor, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'vendorId' is not null or undefined
        if (vendorId === null || vendorId === undefined) {
            throw new RequiredError('Required parameter vendorId was null or undefined when calling voteVendor.');
        }


        // verify required parameter 'voteVendor' is not null or undefined
        if (voteVendor === null || voteVendor === undefined) {
            throw new RequiredError('Required parameter voteVendor was null or undefined when calling voteVendor.');
        }


        // Path Params
        const localVarPath = '/internal/vendors/{vendorId}/votes'
            .replace('{' + 'vendorId' + '}', encodeURIComponent(String(vendorId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(voteVendor, "VoteVendor", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        // Apply auth methods

        return requestContext;
    }

}

export class VendorApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to commentVendor
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async commentVendor(response: ResponseContext): Promise<VendorComment > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("204", response.httpStatusCode)) {
            const body: VendorComment = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "VendorComment", ""
            ) as VendorComment;
            return body;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(404, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: VendorComment = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "VendorComment", ""
            ) as VendorComment;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to deleteVendorCommentImage
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async deleteVendorCommentImage(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(403, body);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(404, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to deleteVendorFavorite
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async deleteVendorFavorite(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(404, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to favorVendor
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async favorVendor(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(404, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to uploadVendorCommentImage
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async uploadVendorCommentImage(response: ResponseContext): Promise<Image > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Image = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Image", ""
            ) as Image;
            return body;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(403, body);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(404, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Image = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Image", ""
            ) as Image;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to voteVendor
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async voteVendor(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(404, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

}
