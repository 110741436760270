/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Badge } from './Badge';
import { BusinesscardConfig } from './BusinesscardConfig';
import { Event } from './Event';
import { Image } from './Image';
import { LocationExtended } from './LocationExtended';
import { OfferingsByCategory } from './OfferingsByCategory';
import { OpeningHours } from './OpeningHours';
import { VendorExtended } from './VendorExtended';
import { HttpFile } from '../http/http';

export class Businesscard {
    'config'?: BusinesscardConfig;
    'vendorExtended'?: VendorExtended;
    'images'?: Array<Image>;
    'middleImage'?: Image;
    'offeringsByCategories'?: Array<OfferingsByCategory>;
    'locationExtended'?: LocationExtended;
    'events'?: Array<Event>;
    'openingHours'?: OpeningHours;
    'badges'?: Array<Badge>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "config",
            "baseName": "config",
            "type": "BusinesscardConfig",
            "format": ""
        },
        {
            "name": "vendorExtended",
            "baseName": "vendor_extended",
            "type": "VendorExtended",
            "format": ""
        },
        {
            "name": "images",
            "baseName": "images",
            "type": "Array<Image>",
            "format": ""
        },
        {
            "name": "middleImage",
            "baseName": "middle_image",
            "type": "Image",
            "format": ""
        },
        {
            "name": "offeringsByCategories",
            "baseName": "offerings_by_categories",
            "type": "Array<OfferingsByCategory>",
            "format": ""
        },
        {
            "name": "locationExtended",
            "baseName": "location_extended",
            "type": "LocationExtended",
            "format": ""
        },
        {
            "name": "events",
            "baseName": "events",
            "type": "Array<Event>",
            "format": ""
        },
        {
            "name": "openingHours",
            "baseName": "opening_hours",
            "type": "OpeningHours",
            "format": ""
        },
        {
            "name": "badges",
            "baseName": "badges",
            "type": "Array<Badge>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Businesscard.attributeTypeMap;
    }
    
    public constructor() {
    }
}

