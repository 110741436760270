/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class BusinesscardConfig {
    'appLandingpageUrl'?: string;
    'appStoreUrl'?: string;
    'playStoreUrl'?: string;
    'gustoEventsUrl'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "appLandingpageUrl",
            "baseName": "app_landingpage_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "appStoreUrl",
            "baseName": "app_store_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "playStoreUrl",
            "baseName": "play_store_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "gustoEventsUrl",
            "baseName": "gusto_events_url",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return BusinesscardConfig.attributeTypeMap;
    }
    
    public constructor() {
    }
}

