const screens = require('../../tailwind.config.js').theme.screens;

export enum QueryMode {
    MIN_WIDTH = 'min-width',
    MAX_WIDTH = 'max-width'
}

export function getMediaQueryFor(mode: QueryMode, screen: string): string
{
    return `(${mode}: ${screens[screen]})`;
}
