




























import {Component, Prop, Vue} from 'vue-property-decorator';
import LazyImage from '@/components/LazyImage.vue';
// eslint-disable-next-line no-unused-vars
import { OfferingsByCategory } from 'abhof-internal-api';

@Component({
    components: {LazyImage}
})
export default class VendorOfferings extends Vue {
    @Prop()
    private apiVendorOfferings!: OfferingsByCategory[];

    get offerings(): OfferingsByCategory[]
    {
        return this.apiVendorOfferings;
    }
}
