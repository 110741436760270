import {Image} from 'abhof-internal-api';

export enum ImageMode {
    SLIDER_SM = 'vendor_wbc_slider_mobile',
    SLIDER_LG = 'vendor_wbc_slider_desktop'
}

export interface ImageUrlProvider {
    getImageUrl(image: Image, mode?: ImageMode): string;
}
