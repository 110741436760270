/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AppTeasers } from './AppTeasers';
import { Vendors } from './Vendors';
import { HttpFile } from '../http/http';

export class AppHome {
    'appTeasers'?: AppTeasers;
    'vendors'?: Vendors;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "appTeasers",
            "baseName": "app_teasers",
            "type": "AppTeasers",
            "format": ""
        },
        {
            "name": "vendors",
            "baseName": "vendors",
            "type": "Vendors",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AppHome.attributeTypeMap;
    }
    
    public constructor() {
    }
}

