/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class OpeningHoursItem {
    'startDate'?: string;
    'endDate'?: string;
    'startTime'?: string;
    'endTime'?: string;
    /**
    * Weekday mask expressed as an int.
    */
    'weekdayMask'?: number;
    'note'?: string;
    /**
    * Children of the same type.
    */
    'children'?: Array<OpeningHoursItem>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "string",
            "format": "date"
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "string",
            "format": "date"
        },
        {
            "name": "startTime",
            "baseName": "start_time",
            "type": "string",
            "format": ""
        },
        {
            "name": "endTime",
            "baseName": "end_time",
            "type": "string",
            "format": ""
        },
        {
            "name": "weekdayMask",
            "baseName": "weekday_mask",
            "type": "number",
            "format": ""
        },
        {
            "name": "note",
            "baseName": "note",
            "type": "string",
            "format": ""
        },
        {
            "name": "children",
            "baseName": "children",
            "type": "Array<OpeningHoursItem>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return OpeningHoursItem.attributeTypeMap;
    }
    
    public constructor() {
    }
}

