/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class AuthToken {
    'authToken'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "authToken",
            "baseName": "auth_token",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AuthToken.attributeTypeMap;
    }
    
    public constructor() {
    }
}

