// TODO: better import syntax?
import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import {Configuration} from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {isCodeInRange} from '../util';

import { Businesscard } from '../models/Businesscard';
import { Error2 } from '../models/Error2';

/**
 * no description
 */
export class BusinesscardApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Get Businesscard
     * @param subdomain Vendor Subdomain
     */
    public async getBusinesscard(subdomain: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'subdomain' is not null or undefined
        if (subdomain === null || subdomain === undefined) {
            throw new RequiredError('Required parameter subdomain was null or undefined when calling getBusinesscard.');
        }


        // Path Params
        const localVarPath = '/internal/businesscard/web/{subdomain}'
            .replace('{' + 'subdomain' + '}', encodeURIComponent(String(subdomain)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params


        // Body Params

        // Apply auth methods

        return requestContext;
    }

}

export class BusinesscardApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getBusinesscard
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async getBusinesscard(response: ResponseContext): Promise<Businesscard > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Businesscard = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Businesscard", ""
            ) as Businesscard;
            return body;
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: Error2 = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error2", ""
            ) as Error2;
            throw new ApiException<Error2>(404, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Businesscard = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Businesscard", ""
            ) as Businesscard;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

}
