/**
 * AbHof API Documentation
 * Relaunched AbHof API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ImageUpload {
    /**
    * Image file as binary
    */
    'file': HttpFile;
    /**
    * X position of the crop area start
    */
    'cropAreaX': string;
    /**
    * Y position of the crop area start
    */
    'cropAreaY': string;
    /**
    * Width of the crop area
    */
    'cropAreaWidth': string;
    /**
    * Height of the crop area
    */
    'cropAreaHeight': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "file",
            "baseName": "file",
            "type": "HttpFile",
            "format": "binary"
        },
        {
            "name": "cropAreaX",
            "baseName": "crop_area_x",
            "type": "string",
            "format": ""
        },
        {
            "name": "cropAreaY",
            "baseName": "crop_area_y",
            "type": "string",
            "format": ""
        },
        {
            "name": "cropAreaWidth",
            "baseName": "crop_area_width",
            "type": "string",
            "format": ""
        },
        {
            "name": "cropAreaHeight",
            "baseName": "crop_area_height",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ImageUpload.attributeTypeMap;
    }
    
    public constructor() {
    }
}

