import {ApiVersion} from '@/common/api-version';
import {ImageMode, ImageUrlProvider} from '@/services/provider/image-url-provider';
import LegacyImageUrlProvider from '@/services/provider/legacy-image-url-provider';
import NewImageUrlProvider from '@/services/provider/new-image-url-provider';
import {Image} from 'abhof-internal-api';

export interface ViewImage {
    url: string;
    altText: string;
}

class ImageService {
    private imageUrlProvider: ImageUrlProvider;

    constructor()
    {
        if (process.env.VUE_APP_IMAGE_PROVIDER_VERSION as number === ApiVersion.LEGACY) {
            this.imageUrlProvider = new LegacyImageUrlProvider();

        } else {
            this.imageUrlProvider = new NewImageUrlProvider();
        }
    }

    public getImages(images: Image[], mode?: ImageMode): ViewImage[]
    {
        return  images.map<ViewImage>(image => this.getImage(image, mode));
    }

    public getImage(image: Image, mode?: ImageMode): ViewImage
    {

        return {
            url: this.imageUrlProvider.getImageUrl(image, mode),
            altText: image.altText || ''
        }
    }
}

export const imageService = new ImageService();
