




import {Component, Prop, Vue} from 'vue-property-decorator';
import lozad from 'lozad';

@Component
export default class LazyImage extends Vue {
    @Prop()
    private url!: string;

    $refs!: {
        image: HTMLElement
    }

    private mounted(): void
    {
        const observer = lozad(this.$el);
        observer.observe();
    }
}
