












































































































import {Component, Vue} from 'vue-property-decorator';
import {apiService} from '@/services/api-service';
// eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars
import {imageService, ViewImage} from '@/services/image-service';

import Navigation from './components/Navigation.vue';
import Footer from './components/Footer.vue';
import Section from './components/Section.vue';
import ImageSlider from './components/ImageSlider.vue';
import Contact from '@/components/Contact.vue';
import OpeningHours from '@/components/OpeningHours.vue';
import Events from '@/components/Events.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import Certificates from '@/components/Certificates.vue';
import VendorOfferings from '@/components/VendorOfferings.vue';
import LazyImage from '@/components/LazyImage.vue';
import Products from '@/components/Products.vue';
import VendorRating from '@/components/VendorRating.vue';

// eslint-disable-next-line no-unused-vars
import {Businesscard} from 'abhof-internal-api';

@Component({
    components: {
        Navigation,
        'app-footer': Footer,
        'app-section': Section,
        ImageSlider,
        Contact,
        OpeningHours,
        Events,
        CookieConsent,
        Certificates,
        VendorOfferings,
        LazyImage,
        Products,
        VendorRating
    },
})
export default class App extends Vue {
    private data: Businesscard|null = null;
    private isProductSectionVisible = true;
    private isShopLinkVisible = false;

    private async created(): Promise<void>
    {
        // new API call
       this.data  = await apiService.getBusinessCard();
    }

    private hideProductSection(): void
    {
        this.isProductSectionVisible = false;
    }

    private showShopLink(): void
    {
        this.isShopLinkVisible = true;
    }

    get middleImage(): ViewImage|undefined
    {
        if (this.data && this.data.vendorExtended && this.data.middleImage) {
            return imageService.getImage(this.data.middleImage)
        }
        return undefined;
    }
}
