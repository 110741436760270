/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class OfferingsByCategory {
    /**
    * Id of the category. Used also display a category picture category_{id}.png
    */
    'id'?: number;
    'name'?: string;
    'offerings'?: Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "offerings",
            "baseName": "offerings",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return OfferingsByCategory.attributeTypeMap;
    }
    
    public constructor() {
    }
}

