/**
 * AbHof API Documentation
 * Relaunched AbHof API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Image } from './Image';
import { Location } from './Location';
import { HttpFile } from '../http/http';

/**
* Extended version of the Vendor schema
*/
export class VendorExtended {
    'id'?: number;
    'name'?: string;
    'description'?: string;
    'rating'?: number;
    'isPartner'?: boolean;
    'location'?: Location;
    'profileImage'?: Image;
    'image'?: Image;
    'websiteUrl'?: string;
    'webshopUrl'?: string;
    'facebookUrl'?: string;
    'instagramUrl'?: string;
    'twitterUrl'?: string;
    'isOrganic'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "rating",
            "baseName": "rating",
            "type": "number",
            "format": ""
        },
        {
            "name": "isPartner",
            "baseName": "is_partner",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "Location",
            "format": ""
        },
        {
            "name": "profileImage",
            "baseName": "profile_image",
            "type": "Image",
            "format": ""
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "Image",
            "format": ""
        },
        {
            "name": "websiteUrl",
            "baseName": "website_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "webshopUrl",
            "baseName": "webshop_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "facebookUrl",
            "baseName": "facebook_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "instagramUrl",
            "baseName": "instagram_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "twitterUrl",
            "baseName": "twitter_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "isOrganic",
            "baseName": "is_organic",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return VendorExtended.attributeTypeMap;
    }
    
    public constructor() {
    }
}

