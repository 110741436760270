/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Image } from './Image';
import { HttpFile } from '../http/http';

export class UserProfile {
    'firstname'?: string;
    'lastname'?: string;
    'nickname'?: string;
    /**
    * property used in responses only
    */
    'email'?: string;
    /**
    * property used in responses only
    */
    'image'?: Image;
    'hasNewsletterSubscription'?: boolean;
    'birthdate'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "firstname",
            "baseName": "firstname",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastname",
            "baseName": "lastname",
            "type": "string",
            "format": ""
        },
        {
            "name": "nickname",
            "baseName": "nickname",
            "type": "string",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": "email"
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "Image",
            "format": ""
        },
        {
            "name": "hasNewsletterSubscription",
            "baseName": "has_newsletter_subscription",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "birthdate",
            "baseName": "birthdate",
            "type": "string",
            "format": "date"
        }    ];

    static getAttributeTypeMap() {
        return UserProfile.attributeTypeMap;
    }
    
    public constructor() {
    }
}

