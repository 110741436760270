/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Contact } from './Contact';
import { Country } from './Country';
import { Region } from './Region';
import { State } from './State';
import { HttpFile } from '../http/http';

/**
* Extended version of the Location schema
*/
export class LocationExtended {
    'latitude'?: number;
    'longitude'?: number;
    /**
    * If a geo position (latitude, longitude) is provided, then a distance can be calculated
    */
    'distance'?: number;
    'name'?: string;
    'street'?: string;
    'houseNumber'?: string;
    'zip'?: string;
    'city'?: string;
    'state'?: State;
    'region'?: Region;
    'country'?: Country;
    'contact'?: Contact;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "latitude",
            "baseName": "latitude",
            "type": "number",
            "format": "float"
        },
        {
            "name": "longitude",
            "baseName": "longitude",
            "type": "number",
            "format": "float"
        },
        {
            "name": "distance",
            "baseName": "distance",
            "type": "number",
            "format": "float"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "street",
            "baseName": "street",
            "type": "string",
            "format": ""
        },
        {
            "name": "houseNumber",
            "baseName": "house_number",
            "type": "string",
            "format": ""
        },
        {
            "name": "zip",
            "baseName": "zip",
            "type": "string",
            "format": ""
        },
        {
            "name": "city",
            "baseName": "city",
            "type": "string",
            "format": ""
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "State",
            "format": ""
        },
        {
            "name": "region",
            "baseName": "region",
            "type": "Region",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "Country",
            "format": ""
        },
        {
            "name": "contact",
            "baseName": "contact",
            "type": "Contact",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return LocationExtended.attributeTypeMap;
    }
    
    public constructor() {
    }
}

