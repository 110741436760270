/**
 * AbHof API Documentation
 * Relaunched AbHof API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ImageVariant } from './ImageVariant';
import { HttpFile } from '../http/http';

export class Image {
    'id'?: number;
    'altText'?: string;
    'title'?: string;
    /**
    * Different variants of the image in size and mime type.
    */
    'variants'?: Array<ImageVariant>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "altText",
            "baseName": "alt_text",
            "type": "string",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "variants",
            "baseName": "variants",
            "type": "Array<ImageVariant>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Image.attributeTypeMap;
    }
    
    public constructor() {
    }
}

