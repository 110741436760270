export class VendorDetectionService {
    private vendorName: string;

    constructor() {
        if (process.env.VUE_APP_VENDOR_NAME && process.env.VUE_APP_VENDOR_NAME !== '') {
            this.vendorName = process.env.VUE_APP_VENDOR_NAME;
        } else {
            this.vendorName = this.getSubdomain();
        }
    }

    public getVendorName(): string
    {
        return this.vendorName;
    }

    private getSubdomain(): string
    {
        const domainParts = window.location.hostname.split('.');
        return domainParts[0];
    }
}

export const vendorDetectionService = new VendorDetectionService();
