




























// eslint-disable-next-line no-unused-vars
import {Component, Prop, Vue} from 'vue-property-decorator';
import {VueAgile} from 'vue-agile';
// eslint-disable-next-line no-unused-vars
import {imageService, ViewImage} from '@/services/image-service';
// eslint-disable-next-line no-unused-vars
import {Image} from 'abhof-internal-api';
// eslint-disable-next-line no-unused-vars
import {ImageMode} from '@/services/provider/image-url-provider';
// eslint-disable-next-line no-unused-vars
import lozad from 'lozad';

@Component({
    components: {
        slider: VueAgile
    }
})
export default class ImageSlider extends Vue {
    @Prop()
    private apiImages!: Image[];
    private imageMode = ImageMode;

    private getImageMode(image: Image, mode: ImageMode): ViewImage
    {
        return imageService.getImage(image, mode);
    }

    private mounted(): void
    {
        const observer = lozad((this.$el as HTMLElement).querySelectorAll('picture'));
        observer.observe()
    }


}
