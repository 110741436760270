/**
 * AbHof Internal API Documentation
 * Internal API is use case based and used by intern applications
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OpeningHoursItem } from './OpeningHoursItem';
import { HttpFile } from '../http/http';

export class OpeningHours {
    'remark'?: string;
    'openWithAppointment'?: boolean;
    /**
    * Periods where opening hours are valid.
    */
    'items'?: Array<OpeningHoursItem>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "remark",
            "baseName": "remark",
            "type": "string",
            "format": ""
        },
        {
            "name": "openWithAppointment",
            "baseName": "open_with_appointment",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "items",
            "baseName": "items",
            "type": "Array<OpeningHoursItem>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return OpeningHours.attributeTypeMap;
    }
    
    public constructor() {
    }
}

