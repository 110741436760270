import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'

import { AbuseReport } from '../models/AbuseReport';
import { AppConfig } from '../models/AppConfig';
import { AppHome } from '../models/AppHome';
import { AppSearchTile } from '../models/AppSearchTile';
import { AppSearchTiles } from '../models/AppSearchTiles';
import { AppTeaser } from '../models/AppTeaser';
import { AppTeasers } from '../models/AppTeasers';
import { AppVendorDetails } from '../models/AppVendorDetails';
import { AuthToken } from '../models/AuthToken';
import { Badge } from '../models/Badge';
import { Businesscard } from '../models/Businesscard';
import { BusinesscardConfig } from '../models/BusinesscardConfig';
import { CommentVendor } from '../models/CommentVendor';
import { Contact } from '../models/Contact';
import { Country } from '../models/Country';
import { Email } from '../models/Email';
import { EmailConfirmationStatus } from '../models/EmailConfirmationStatus';
import { Error2 } from '../models/Error2';
import { Event } from '../models/Event';
import { FirebaseSigninData } from '../models/FirebaseSigninData';
import { Image } from '../models/Image';
import { ImageVariant } from '../models/ImageVariant';
import { Location } from '../models/Location';
import { LocationExtended } from '../models/LocationExtended';
import { ModelError } from '../models/ModelError';
import { NewPassword } from '../models/NewPassword';
import { OfferingsByCategory } from '../models/OfferingsByCategory';
import { OpeningHours } from '../models/OpeningHours';
import { OpeningHoursItem } from '../models/OpeningHoursItem';
import { PushNotificationNewProduct } from '../models/PushNotificationNewProduct';
import { PushNotificationToken } from '../models/PushNotificationToken';
import { Region } from '../models/Region';
import { SigninData } from '../models/SigninData';
import { SigninResponse } from '../models/SigninResponse';
import { SignupData } from '../models/SignupData';
import { State } from '../models/State';
import { UserProfile } from '../models/UserProfile';
import { Vendor } from '../models/Vendor';
import { VendorComment } from '../models/VendorComment';
import { VendorExtended } from '../models/VendorExtended';
import { VendorSort } from '../models/VendorSort';
import { VendorUserData } from '../models/VendorUserData';
import { Vendors } from '../models/Vendors';
import { VoteVendor } from '../models/VoteVendor';
import { ObservableAppApi } from './ObservableAPI';

import { AppApiRequestFactory, AppApiResponseProcessor} from "../apis/AppApi";
export class PromiseAppApi {
    private api: ObservableAppApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AppApiRequestFactory,
        responseProcessor?: AppApiResponseProcessor
    ) {
        this.api = new ObservableAppApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Report comment abuse.
     * @param abuseReport Report data. Comment and reason
     */
    public _null(abuseReport: AbuseReport, _options?: Configuration): Promise<void> {
        const result = this.api._null(abuseReport, _options);
        return result.toPromise();
    }

    /**
     * Provides urls for external resources like privacy terms, marketplace. Also provides API version.
     * App configuration
     */
    public getAppConfig(_options?: Configuration): Promise<AppConfig> {
        const result = this.api.getAppConfig(_options);
        return result.toPromise();
    }

    /**
     * This endpoint delivers to types of data: teasers and vendors. This endpoint uses a subset of parameters for the `/vendors` endpoint to control the vendor list.
     * Provides data for the app home screen.
     * @param isPartner Show partner vendors, non-partners vendors or all
     * @param sort Sort vendors by ...
     * @param latitude Use geo position for sorting by distance
     * @param longitude Use geo position for sorting by distance
     * @param page Page number
     * @param size Page size
     */
    public getAppHome(isPartner?: boolean, sort?: VendorSort, latitude?: number, longitude?: number, page?: number, size?: number, _options?: Configuration): Promise<AppHome> {
        const result = this.api.getAppHome(isPartner, sort, latitude, longitude, page, size, _options);
        return result.toPromise();
    }

    /**
     * Search tiles are used on the search screen. A fast way to search for a set of offering categories.
     * Returns the search tiles
     */
    public getAppSearchTiles(_options?: Configuration): Promise<AppSearchTiles> {
        const result = this.api.getAppSearchTiles(_options);
        return result.toPromise();
    }

    /**
     * Contains data necessary to populate the over the fold screen of the detail page with data. `latitude` and `longitude` are used to calculate the vendors distance the the user
     * Vendor details for the app detail page
     * @param id Vendor ID
     * @param latitude Use geo position to get the distance
     * @param longitude Use geo position to get the distance
     */
    public getAppVendorDetails(id: number, latitude?: number, longitude?: number, _options?: Configuration): Promise<AppVendorDetails> {
        const result = this.api.getAppVendorDetails(id, latitude, longitude, _options);
        return result.toPromise();
    }


}



import { ObservableAuthApi } from './ObservableAPI';

import { AuthApiRequestFactory, AuthApiResponseProcessor} from "../apis/AuthApi";
export class PromiseAuthApi {
    private api: ObservableAuthApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthApiRequestFactory,
        responseProcessor?: AuthApiResponseProcessor
    ) {
        this.api = new ObservableAuthApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param email E-Mail data
     */
    public changeEmail(email: Email, _options?: Configuration): Promise<void> {
        const result = this.api.changeEmail(email, _options);
        return result.toPromise();
    }

    /**
     * Endpoint to change user password. It needs the current password and the new one. Please force the user to retype the new password in client to assure there is mistake.
     * @param newPassword Current and new passwords
     */
    public changePassword(newPassword: NewPassword, _options?: Configuration): Promise<void> {
        const result = this.api.changePassword(newPassword, _options);
        return result.toPromise();
    }

    /**
     * Firebase signin after a successfull authentication process on the client side. Please be aware that it is possible, that a firebase user can have an unverified email address. This happens with facebook provided users. In such case a 202 is returned and a confirmation mail is sent to the user. Meanwhile you should wait for user confirmed his email address.
     * @param firebaseSigninData Signup user data
     */
    public firebaseSignin(firebaseSigninData: FirebaseSigninData, _options?: Configuration): Promise<SigninResponse> {
        const result = this.api.firebaseSignin(firebaseSigninData, _options);
        return result.toPromise();
    }

    /**
     * After a mail with confirmation link is sent, here is it possible to check whether the user has confirmed his email address. To select the user in question his `authToken` is necessary. This endpoint is not secured by bearer authentication. Just use the `authToken` as path parameter.
     * @param authToken Authentication token of a fresh registered user.
     */
    public getEmailConfirmationStatus(authToken: string, _options?: Configuration): Promise<EmailConfirmationStatus> {
        const result = this.api.getEmailConfirmationStatus(authToken, _options);
        return result.toPromise();
    }

    /**
     * This endpoint resends the confirmation email. The endpoint is not secured by bearer authentication but it uses the `authToken` created by registration to find the user to which the email should be sent
     * @param authToken Auth token of the user to which should email confirmation be sent
     */
    public resendConfirmationEmail(authToken: AuthToken, _options?: Configuration): Promise<void> {
        const result = this.api.resendConfirmationEmail(authToken, _options);
        return result.toPromise();
    }

    /**
     * @param email E-Mail data
     */
    public resetPassword(email: Email, _options?: Configuration): Promise<void> {
        const result = this.api.resetPassword(email, _options);
        return result.toPromise();
    }

    /**
     * Sign in a user and provide his authentication token. Be aware, that a fresh signed up user has may not completed the email address confirmation process. In this case the response will be `202` and the `is_email_address_confirmated` property will be set to true. Also a confirmation mail is sent in background.
     * @param signinData Signin user data
     */
    public signin(signinData: SigninData, _options?: Configuration): Promise<void | SigninResponse> {
        const result = this.api.signin(signinData, _options);
        return result.toPromise();
    }

    /**
     * User's authentication token gets destroyed.
     */
    public signout(_options?: Configuration): Promise<void> {
        const result = this.api.signout(_options);
        return result.toPromise();
    }

    /**
     * @param signupData Signup user data
     */
    public signup(signupData: SignupData, _options?: Configuration): Promise<SigninResponse> {
        const result = this.api.signup(signupData, _options);
        return result.toPromise();
    }


}



import { ObservableBusinesscardApi } from './ObservableAPI';

import { BusinesscardApiRequestFactory, BusinesscardApiResponseProcessor} from "../apis/BusinesscardApi";
export class PromiseBusinesscardApi {
    private api: ObservableBusinesscardApi

    public constructor(
        configuration: Configuration,
        requestFactory?: BusinesscardApiRequestFactory,
        responseProcessor?: BusinesscardApiResponseProcessor
    ) {
        this.api = new ObservableBusinesscardApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get Businesscard
     * @param subdomain Vendor Subdomain
     */
    public getBusinesscard(subdomain: string, _options?: Configuration): Promise<Businesscard> {
        const result = this.api.getBusinesscard(subdomain, _options);
        return result.toPromise();
    }


}



import { ObservableMeApi } from './ObservableAPI';

import { MeApiRequestFactory, MeApiResponseProcessor} from "../apis/MeApi";
export class PromiseMeApi {
    private api: ObservableMeApi

    public constructor(
        configuration: Configuration,
        requestFactory?: MeApiRequestFactory,
        responseProcessor?: MeApiResponseProcessor
    ) {
        this.api = new ObservableMeApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get list of favorite vendors. To display distance of vendor and sort by it - you need to provide the sort, latitude, longitude parameters.
     * @param sort 
     * @param latitude Use geo position for sorting by distance
     * @param longitude Use geo position for sorting by distance
     */
    public getFavoriteVendors(sort?: VendorSort, latitude?: number, longitude?: number, _options?: Configuration): Promise<Vendors> {
        const result = this.api.getFavoriteVendors(sort, latitude, longitude, _options);
        return result.toPromise();
    }

    /**
     * Get user profile
     */
    public getUserProfile(_options?: Configuration): Promise<UserProfile> {
        const result = this.api.getUserProfile(_options);
        return result.toPromise();
    }

    /**
     * Update users profile. Also subscribes/unsubscribes the user to newsletter if `has_newsletter_subscription` set to `true`. To change properties like profile image or email - use according endpoints.
     * @param userProfile User profile data
     */
    public updateUserProfile(userProfile: UserProfile, _options?: Configuration): Promise<void> {
        const result = this.api.updateUserProfile(userProfile, _options);
        return result.toPromise();
    }

    /**
     * Upload the user profile image. The upload consists of two parts: original image and the crop area. The image should be in the original size not less than 250x250px. The other part is the cropping area. The cropping area consists of the X and Y position of the area and it's width and height.
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public uploadProfileImage(file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Promise<void> {
        const result = this.api.uploadProfileImage(file, cropAreaX, cropAreaY, cropAreaWidth, cropAreaHeight, _options);
        return result.toPromise();
    }


}



import { ObservablePushnotificationApi } from './ObservableAPI';

import { PushnotificationApiRequestFactory, PushnotificationApiResponseProcessor} from "../apis/PushnotificationApi";
export class PromisePushnotificationApi {
    private api: ObservablePushnotificationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: PushnotificationApiRequestFactory,
        responseProcessor?: PushnotificationApiResponseProcessor
    ) {
        this.api = new ObservablePushnotificationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Send PushNotification to users when a new Product has been created/updated.
     * @param pushNotificationNewProduct Vendor and Product data
     */
    public newProductPushNotification(pushNotificationNewProduct: PushNotificationNewProduct, _options?: Configuration): Promise<void> {
        const result = this.api.newProductPushNotification(pushNotificationNewProduct, _options);
        return result.toPromise();
    }

    /**
     * Store or Update Push Notification Device settings.
     * @param pushNotificationToken Device data
     */
    public storePushNotificationToken(pushNotificationToken: PushNotificationToken, _options?: Configuration): Promise<void> {
        const result = this.api.storePushNotificationToken(pushNotificationToken, _options);
        return result.toPromise();
    }


}



import { ObservableVendorApi } from './ObservableAPI';

import { VendorApiRequestFactory, VendorApiResponseProcessor} from "../apis/VendorApi";
export class PromiseVendorApi {
    private api: ObservableVendorApi

    public constructor(
        configuration: Configuration,
        requestFactory?: VendorApiRequestFactory,
        responseProcessor?: VendorApiResponseProcessor
    ) {
        this.api = new ObservableVendorApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Comment a Vendor as User.
     * @param vendorId 
     * @param commentVendor Vendor
     */
    public commentVendor(vendorId: number, commentVendor: CommentVendor, _options?: Configuration): Promise<VendorComment> {
        const result = this.api.commentVendor(vendorId, commentVendor, _options);
        return result.toPromise();
    }

    /**
     * Delete Vendor Comment Image.
     * @param vendorId 
     * @param commentId 
     * @param imageId 
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public deleteVendorCommentImage(vendorId: number, commentId: number, imageId: number, file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Promise<void> {
        const result = this.api.deleteVendorCommentImage(vendorId, commentId, imageId, file, cropAreaX, cropAreaY, cropAreaWidth, cropAreaHeight, _options);
        return result.toPromise();
    }

    /**
     * Delete User Vendor Favorite.
     * @param vendorId 
     */
    public deleteVendorFavorite(vendorId: number, _options?: Configuration): Promise<void> {
        const result = this.api.deleteVendorFavorite(vendorId, _options);
        return result.toPromise();
    }

    /**
     * Favor a Vendor as User.
     * @param vendorId 
     */
    public favorVendor(vendorId: number, _options?: Configuration): Promise<void> {
        const result = this.api.favorVendor(vendorId, _options);
        return result.toPromise();
    }

    /**
     * Upload an Image for a Vendor Comment.
     * @param vendorId 
     * @param commentId 
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public uploadVendorCommentImage(vendorId: number, commentId: number, file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Promise<Image> {
        const result = this.api.uploadVendorCommentImage(vendorId, commentId, file, cropAreaX, cropAreaY, cropAreaWidth, cropAreaHeight, _options);
        return result.toPromise();
    }

    /**
     * Rate a Vendor as User.
     * @param vendorId 
     * @param voteVendor Vendor
     */
    public voteVendor(vendorId: number, voteVendor: VoteVendor, _options?: Configuration): Promise<void> {
        const result = this.api.voteVendor(vendorId, voteVendor, _options);
        return result.toPromise();
    }


}



