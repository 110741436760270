// TODO: better import syntax?
import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import {Configuration} from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {isCodeInRange} from '../util';

import { UserProfile } from '../models/UserProfile';
import { VendorSort } from '../models/VendorSort';
import { Vendors } from '../models/Vendors';

/**
 * no description
 */
export class MeApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Get list of favorite vendors. To display distance of vendor and sort by it - you need to provide the sort, latitude, longitude parameters.
     * @param sort 
     * @param latitude Use geo position for sorting by distance
     * @param longitude Use geo position for sorting by distance
     */
    public async getFavoriteVendors(sort?: VendorSort, latitude?: number, longitude?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;




        // Path Params
        const localVarPath = '/internal/me/favorite-vendors';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (sort !== undefined) {
            requestContext.setQueryParam("sort", ObjectSerializer.serialize(sort, "VendorSort", ""));
        }
        if (latitude !== undefined) {
            requestContext.setQueryParam("latitude", ObjectSerializer.serialize(latitude, "number", "float"));
        }
        if (longitude !== undefined) {
            requestContext.setQueryParam("longitude", ObjectSerializer.serialize(longitude, "number", "float"));
        }

        // Header Params

        // Form Params


        // Body Params

        let authMethod = null;
        // Apply auth methods
        authMethod = _config.authMethods["AuthToken"]
        if (authMethod) {
            await authMethod.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get user profile
     */
    public async getUserProfile(_options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // Path Params
        const localVarPath = '/internal/me/profile';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params


        // Body Params

        let authMethod = null;
        // Apply auth methods
        authMethod = _config.authMethods["AuthToken"]
        if (authMethod) {
            await authMethod.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Update users profile. Also subscribes/unsubscribes the user to newsletter if `has_newsletter_subscription` set to `true`. To change properties like profile image or email - use according endpoints.
     * @param userProfile User profile data
     */
    public async updateUserProfile(userProfile: UserProfile, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userProfile' is not null or undefined
        if (userProfile === null || userProfile === undefined) {
            throw new RequiredError('Required parameter userProfile was null or undefined when calling updateUserProfile.');
        }


        // Path Params
        const localVarPath = '/internal/me/profile';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PUT);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(userProfile, "UserProfile", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod = null;
        // Apply auth methods
        authMethod = _config.authMethods["AuthToken"]
        if (authMethod) {
            await authMethod.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Upload the user profile image. The upload consists of two parts: original image and the crop area. The image should be in the original size not less than 250x250px. The other part is the cropping area. The cropping area consists of the X and Y position of the area and it's width and height.
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public async uploadProfileImage(file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'file' is not null or undefined
        if (file === null || file === undefined) {
            throw new RequiredError('Required parameter file was null or undefined when calling uploadProfileImage.');
        }


        // verify required parameter 'cropAreaX' is not null or undefined
        if (cropAreaX === null || cropAreaX === undefined) {
            throw new RequiredError('Required parameter cropAreaX was null or undefined when calling uploadProfileImage.');
        }


        // verify required parameter 'cropAreaY' is not null or undefined
        if (cropAreaY === null || cropAreaY === undefined) {
            throw new RequiredError('Required parameter cropAreaY was null or undefined when calling uploadProfileImage.');
        }


        // verify required parameter 'cropAreaWidth' is not null or undefined
        if (cropAreaWidth === null || cropAreaWidth === undefined) {
            throw new RequiredError('Required parameter cropAreaWidth was null or undefined when calling uploadProfileImage.');
        }


        // verify required parameter 'cropAreaHeight' is not null or undefined
        if (cropAreaHeight === null || cropAreaHeight === undefined) {
            throw new RequiredError('Required parameter cropAreaHeight was null or undefined when calling uploadProfileImage.');
        }


        // Path Params
        const localVarPath = '/internal/me/profile/image';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params
        let localVarFormParams = new FormData();

        if (file !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('file', file, file.name);
        }
        if (cropAreaX !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_x', cropAreaX as any);
        }
        if (cropAreaY !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_y', cropAreaY as any);
        }
        if (cropAreaWidth !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_width', cropAreaWidth as any);
        }
        if (cropAreaHeight !== undefined) {
             // TODO: replace .append with .set
             localVarFormParams.append('crop_area_height', cropAreaHeight as any);
        }
        requestContext.setBody(localVarFormParams);

        // Body Params

        let authMethod = null;
        // Apply auth methods
        authMethod = _config.authMethods["AuthToken"]
        if (authMethod) {
            await authMethod.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class MeApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getFavoriteVendors
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async getFavoriteVendors(response: ResponseContext): Promise<Vendors > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Vendors = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Vendors", ""
            ) as Vendors;
            return body;
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(401, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Vendors = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Vendors", ""
            ) as Vendors;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getUserProfile
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async getUserProfile(response: ResponseContext): Promise<UserProfile > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: UserProfile = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "UserProfile", ""
            ) as UserProfile;
            return body;
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(401, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: UserProfile = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "UserProfile", ""
            ) as UserProfile;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to updateUserProfile
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async updateUserProfile(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(401, body);
        }
        if (isCodeInRange("405", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(405, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to uploadProfileImage
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async uploadProfileImage(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(400, body);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: Error = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Error", ""
            ) as Error;
            throw new ApiException<Error>(401, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

}
