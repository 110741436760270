import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { AbuseReport } from '../models/AbuseReport';
import { AppConfig } from '../models/AppConfig';
import { AppHome } from '../models/AppHome';
import { AppSearchTile } from '../models/AppSearchTile';
import { AppSearchTiles } from '../models/AppSearchTiles';
import { AppTeaser } from '../models/AppTeaser';
import { AppTeasers } from '../models/AppTeasers';
import { AppVendorDetails } from '../models/AppVendorDetails';
import { AuthToken } from '../models/AuthToken';
import { Badge } from '../models/Badge';
import { Businesscard } from '../models/Businesscard';
import { BusinesscardConfig } from '../models/BusinesscardConfig';
import { CommentVendor } from '../models/CommentVendor';
import { Contact } from '../models/Contact';
import { Country } from '../models/Country';
import { Email } from '../models/Email';
import { EmailConfirmationStatus } from '../models/EmailConfirmationStatus';
import { Error2 } from '../models/Error2';
import { Event } from '../models/Event';
import { FirebaseSigninData } from '../models/FirebaseSigninData';
import { Image } from '../models/Image';
import { ImageVariant } from '../models/ImageVariant';
import { Location } from '../models/Location';
import { LocationExtended } from '../models/LocationExtended';
import { ModelError } from '../models/ModelError';
import { NewPassword } from '../models/NewPassword';
import { OfferingsByCategory } from '../models/OfferingsByCategory';
import { OpeningHours } from '../models/OpeningHours';
import { OpeningHoursItem } from '../models/OpeningHoursItem';
import { PushNotificationNewProduct } from '../models/PushNotificationNewProduct';
import { PushNotificationToken } from '../models/PushNotificationToken';
import { Region } from '../models/Region';
import { SigninData } from '../models/SigninData';
import { SigninResponse } from '../models/SigninResponse';
import { SignupData } from '../models/SignupData';
import { State } from '../models/State';
import { UserProfile } from '../models/UserProfile';
import { Vendor } from '../models/Vendor';
import { VendorComment } from '../models/VendorComment';
import { VendorExtended } from '../models/VendorExtended';
import { VendorSort } from '../models/VendorSort';
import { VendorUserData } from '../models/VendorUserData';
import { Vendors } from '../models/Vendors';
import { VoteVendor } from '../models/VoteVendor';

import { AppApiRequestFactory, AppApiResponseProcessor} from "../apis/AppApi";
export class ObservableAppApi {
    private requestFactory: AppApiRequestFactory;
    private responseProcessor: AppApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AppApiRequestFactory,
        responseProcessor?: AppApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AppApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AppApiResponseProcessor();
    }

    /**
     * Report comment abuse.
     * @param abuseReport Report data. Comment and reason
     */
    public _null(abuseReport: AbuseReport, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory._null(abuseReport, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor._null(rsp)));
            }));
    }
 
    /**
     * Provides urls for external resources like privacy terms, marketplace. Also provides API version.
     * App configuration
     */
    public getAppConfig(_options?: Configuration): Observable<AppConfig> {
        const requestContextPromise = this.requestFactory.getAppConfig(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAppConfig(rsp)));
            }));
    }
 
    /**
     * This endpoint delivers to types of data: teasers and vendors. This endpoint uses a subset of parameters for the `/vendors` endpoint to control the vendor list.
     * Provides data for the app home screen.
     * @param isPartner Show partner vendors, non-partners vendors or all
     * @param sort Sort vendors by ...
     * @param latitude Use geo position for sorting by distance
     * @param longitude Use geo position for sorting by distance
     * @param page Page number
     * @param size Page size
     */
    public getAppHome(isPartner?: boolean, sort?: VendorSort, latitude?: number, longitude?: number, page?: number, size?: number, _options?: Configuration): Observable<AppHome> {
        const requestContextPromise = this.requestFactory.getAppHome(isPartner, sort, latitude, longitude, page, size, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAppHome(rsp)));
            }));
    }
 
    /**
     * Search tiles are used on the search screen. A fast way to search for a set of offering categories.
     * Returns the search tiles
     */
    public getAppSearchTiles(_options?: Configuration): Observable<AppSearchTiles> {
        const requestContextPromise = this.requestFactory.getAppSearchTiles(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAppSearchTiles(rsp)));
            }));
    }
 
    /**
     * Contains data necessary to populate the over the fold screen of the detail page with data. `latitude` and `longitude` are used to calculate the vendors distance the the user
     * Vendor details for the app detail page
     * @param id Vendor ID
     * @param latitude Use geo position to get the distance
     * @param longitude Use geo position to get the distance
     */
    public getAppVendorDetails(id: number, latitude?: number, longitude?: number, _options?: Configuration): Observable<AppVendorDetails> {
        const requestContextPromise = this.requestFactory.getAppVendorDetails(id, latitude, longitude, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAppVendorDetails(rsp)));
            }));
    }
 
}

import { AuthApiRequestFactory, AuthApiResponseProcessor} from "../apis/AuthApi";
export class ObservableAuthApi {
    private requestFactory: AuthApiRequestFactory;
    private responseProcessor: AuthApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthApiRequestFactory,
        responseProcessor?: AuthApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AuthApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AuthApiResponseProcessor();
    }

    /**
     * @param email E-Mail data
     */
    public changeEmail(email: Email, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.changeEmail(email, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.changeEmail(rsp)));
            }));
    }
 
    /**
     * Endpoint to change user password. It needs the current password and the new one. Please force the user to retype the new password in client to assure there is mistake.
     * @param newPassword Current and new passwords
     */
    public changePassword(newPassword: NewPassword, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.changePassword(newPassword, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.changePassword(rsp)));
            }));
    }
 
    /**
     * Firebase signin after a successfull authentication process on the client side. Please be aware that it is possible, that a firebase user can have an unverified email address. This happens with facebook provided users. In such case a 202 is returned and a confirmation mail is sent to the user. Meanwhile you should wait for user confirmed his email address.
     * @param firebaseSigninData Signup user data
     */
    public firebaseSignin(firebaseSigninData: FirebaseSigninData, _options?: Configuration): Observable<SigninResponse> {
        const requestContextPromise = this.requestFactory.firebaseSignin(firebaseSigninData, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.firebaseSignin(rsp)));
            }));
    }
 
    /**
     * After a mail with confirmation link is sent, here is it possible to check whether the user has confirmed his email address. To select the user in question his `authToken` is necessary. This endpoint is not secured by bearer authentication. Just use the `authToken` as path parameter.
     * @param authToken Authentication token of a fresh registered user.
     */
    public getEmailConfirmationStatus(authToken: string, _options?: Configuration): Observable<EmailConfirmationStatus> {
        const requestContextPromise = this.requestFactory.getEmailConfirmationStatus(authToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getEmailConfirmationStatus(rsp)));
            }));
    }
 
    /**
     * This endpoint resends the confirmation email. The endpoint is not secured by bearer authentication but it uses the `authToken` created by registration to find the user to which the email should be sent
     * @param authToken Auth token of the user to which should email confirmation be sent
     */
    public resendConfirmationEmail(authToken: AuthToken, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.resendConfirmationEmail(authToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.resendConfirmationEmail(rsp)));
            }));
    }
 
    /**
     * @param email E-Mail data
     */
    public resetPassword(email: Email, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.resetPassword(email, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.resetPassword(rsp)));
            }));
    }
 
    /**
     * Sign in a user and provide his authentication token. Be aware, that a fresh signed up user has may not completed the email address confirmation process. In this case the response will be `202` and the `is_email_address_confirmated` property will be set to true. Also a confirmation mail is sent in background.
     * @param signinData Signin user data
     */
    public signin(signinData: SigninData, _options?: Configuration): Observable<void | SigninResponse> {
        const requestContextPromise = this.requestFactory.signin(signinData, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.signin(rsp)));
            }));
    }
 
    /**
     * User's authentication token gets destroyed.
     */
    public signout(_options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.signout(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.signout(rsp)));
            }));
    }
 
    /**
     * @param signupData Signup user data
     */
    public signup(signupData: SignupData, _options?: Configuration): Observable<SigninResponse> {
        const requestContextPromise = this.requestFactory.signup(signupData, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.signup(rsp)));
            }));
    }
 
}

import { BusinesscardApiRequestFactory, BusinesscardApiResponseProcessor} from "../apis/BusinesscardApi";
export class ObservableBusinesscardApi {
    private requestFactory: BusinesscardApiRequestFactory;
    private responseProcessor: BusinesscardApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: BusinesscardApiRequestFactory,
        responseProcessor?: BusinesscardApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new BusinesscardApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new BusinesscardApiResponseProcessor();
    }

    /**
     * Get Businesscard
     * @param subdomain Vendor Subdomain
     */
    public getBusinesscard(subdomain: string, _options?: Configuration): Observable<Businesscard> {
        const requestContextPromise = this.requestFactory.getBusinesscard(subdomain, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getBusinesscard(rsp)));
            }));
    }
 
}

import { MeApiRequestFactory, MeApiResponseProcessor} from "../apis/MeApi";
export class ObservableMeApi {
    private requestFactory: MeApiRequestFactory;
    private responseProcessor: MeApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: MeApiRequestFactory,
        responseProcessor?: MeApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new MeApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new MeApiResponseProcessor();
    }

    /**
     * Get list of favorite vendors. To display distance of vendor and sort by it - you need to provide the sort, latitude, longitude parameters.
     * @param sort 
     * @param latitude Use geo position for sorting by distance
     * @param longitude Use geo position for sorting by distance
     */
    public getFavoriteVendors(sort?: VendorSort, latitude?: number, longitude?: number, _options?: Configuration): Observable<Vendors> {
        const requestContextPromise = this.requestFactory.getFavoriteVendors(sort, latitude, longitude, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getFavoriteVendors(rsp)));
            }));
    }
 
    /**
     * Get user profile
     */
    public getUserProfile(_options?: Configuration): Observable<UserProfile> {
        const requestContextPromise = this.requestFactory.getUserProfile(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getUserProfile(rsp)));
            }));
    }
 
    /**
     * Update users profile. Also subscribes/unsubscribes the user to newsletter if `has_newsletter_subscription` set to `true`. To change properties like profile image or email - use according endpoints.
     * @param userProfile User profile data
     */
    public updateUserProfile(userProfile: UserProfile, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.updateUserProfile(userProfile, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateUserProfile(rsp)));
            }));
    }
 
    /**
     * Upload the user profile image. The upload consists of two parts: original image and the crop area. The image should be in the original size not less than 250x250px. The other part is the cropping area. The cropping area consists of the X and Y position of the area and it's width and height.
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public uploadProfileImage(file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.uploadProfileImage(file, cropAreaX, cropAreaY, cropAreaWidth, cropAreaHeight, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.uploadProfileImage(rsp)));
            }));
    }
 
}

import { PushnotificationApiRequestFactory, PushnotificationApiResponseProcessor} from "../apis/PushnotificationApi";
export class ObservablePushnotificationApi {
    private requestFactory: PushnotificationApiRequestFactory;
    private responseProcessor: PushnotificationApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: PushnotificationApiRequestFactory,
        responseProcessor?: PushnotificationApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new PushnotificationApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new PushnotificationApiResponseProcessor();
    }

    /**
     * Send PushNotification to users when a new Product has been created/updated.
     * @param pushNotificationNewProduct Vendor and Product data
     */
    public newProductPushNotification(pushNotificationNewProduct: PushNotificationNewProduct, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.newProductPushNotification(pushNotificationNewProduct, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.newProductPushNotification(rsp)));
            }));
    }
 
    /**
     * Store or Update Push Notification Device settings.
     * @param pushNotificationToken Device data
     */
    public storePushNotificationToken(pushNotificationToken: PushNotificationToken, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.storePushNotificationToken(pushNotificationToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.storePushNotificationToken(rsp)));
            }));
    }
 
}

import { VendorApiRequestFactory, VendorApiResponseProcessor} from "../apis/VendorApi";
export class ObservableVendorApi {
    private requestFactory: VendorApiRequestFactory;
    private responseProcessor: VendorApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: VendorApiRequestFactory,
        responseProcessor?: VendorApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new VendorApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new VendorApiResponseProcessor();
    }

    /**
     * Comment a Vendor as User.
     * @param vendorId 
     * @param commentVendor Vendor
     */
    public commentVendor(vendorId: number, commentVendor: CommentVendor, _options?: Configuration): Observable<VendorComment> {
        const requestContextPromise = this.requestFactory.commentVendor(vendorId, commentVendor, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.commentVendor(rsp)));
            }));
    }
 
    /**
     * Delete Vendor Comment Image.
     * @param vendorId 
     * @param commentId 
     * @param imageId 
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public deleteVendorCommentImage(vendorId: number, commentId: number, imageId: number, file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteVendorCommentImage(vendorId, commentId, imageId, file, cropAreaX, cropAreaY, cropAreaWidth, cropAreaHeight, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteVendorCommentImage(rsp)));
            }));
    }
 
    /**
     * Delete User Vendor Favorite.
     * @param vendorId 
     */
    public deleteVendorFavorite(vendorId: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteVendorFavorite(vendorId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteVendorFavorite(rsp)));
            }));
    }
 
    /**
     * Favor a Vendor as User.
     * @param vendorId 
     */
    public favorVendor(vendorId: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.favorVendor(vendorId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.favorVendor(rsp)));
            }));
    }
 
    /**
     * Upload an Image for a Vendor Comment.
     * @param vendorId 
     * @param commentId 
     * @param file Image file as binary
     * @param cropAreaX X position of the crop area start
     * @param cropAreaY Y position of the crop area start
     * @param cropAreaWidth Width of the crop area
     * @param cropAreaHeight Height of the crop area
     */
    public uploadVendorCommentImage(vendorId: number, commentId: number, file: HttpFile, cropAreaX: string, cropAreaY: string, cropAreaWidth: string, cropAreaHeight: string, _options?: Configuration): Observable<Image> {
        const requestContextPromise = this.requestFactory.uploadVendorCommentImage(vendorId, commentId, file, cropAreaX, cropAreaY, cropAreaWidth, cropAreaHeight, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.uploadVendorCommentImage(rsp)));
            }));
    }
 
    /**
     * Rate a Vendor as User.
     * @param vendorId 
     * @param voteVendor Vendor
     */
    public voteVendor(vendorId: number, voteVendor: VoteVendor, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.voteVendor(vendorId, voteVendor, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.voteVendor(rsp)));
            }));
    }
 
}
