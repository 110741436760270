import { OpeningHoursItem } from "abhof-internal-api";

export interface Weekday {
    dayName: number|string;
    dayBit: number;
    openingHoursIntervals: Array<HourInterval>;
}

interface HourInterval {
    readonly start: string;
    readonly end: string;
}

class OpeningHoursService {

    private weekdayNames = new Map<number, string>([
        [1, 'Mo'],
        [2, 'Di'],
        [4, 'Mi'],
        [8, 'Do'],
        [16, 'Fr'],
        [32, 'Sa'],
        [64, 'So']
    ]);

    public getHoursItemData(openingHours: Array<OpeningHoursItem>): Weekday[] {
        let activeInterval = this.getActiveInterval(openingHours);
        if (typeof activeInterval === 'undefined') {
            return [];
        }

        let weekdays: Weekday[] = this.collectOpeningHoursPerWeekday(activeInterval);
        let weekdaysSorted: Weekday[] = this.sortWeekdays(weekdays);

        return weekdaysSorted;
    }

    private getActiveInterval(intervals: Array<OpeningHoursItem>): OpeningHoursItem|undefined {
        return intervals.find(interval => {
            if (typeof interval.startDate === 'undefined' && typeof interval.endDate === 'undefined') {
                return true;
            }

            let now = new Date();
            let startDate = new Date(interval.startDate!);
            let endDate = new Date(interval.endDate!)

            return startDate <= now && endDate >= now;
        });
    }

    private collectOpeningHoursPerWeekday(interval: OpeningHoursItem): Weekday[]
    {
        let openingHoursPerWeekday: Weekday[] = [];
        this.weekdayNames.forEach((weekdayName, bit) => {
            let newWeekday = <Weekday>{dayName: weekdayName, dayBit: bit, openingHoursIntervals: []};
            interval.children?.forEach(item => {
                if ((item.weekdayMask! & bit) === bit) {
                    let hourInterval = <HourInterval>{start: item.startTime, end: item.endTime};
                    newWeekday.openingHoursIntervals.push(hourInterval);
                }
            });

            if (newWeekday.openingHoursIntervals.length > 0) {
                openingHoursPerWeekday.push(newWeekday);
            }
        });

        return openingHoursPerWeekday;
    }

    private sortWeekdays(weekdays: Weekday[]): Weekday[] {
        return weekdays.sort((a, b) => {
            if (a.dayBit < b.dayBit) {
                return -1;
            } else if (a.dayBit > b.dayBit) {
                return 1;
            } else {
                return 0;
            }
        });
    }
}

export const openingHoursService = new OpeningHoursService();
