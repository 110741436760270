













































import {Component, Vue, Prop} from 'vue-property-decorator';
// eslint-disable-next-line no-unused-vars
import { BusinesscardConfig } from 'abhof-internal-api';

@Component
export default class Footer extends Vue {
    @Prop()
    private config!: BusinesscardConfig;

    get links(): Record<string, string>
    {
        return {
            'https://app.abhofladen.at/impressum.html': 'Impressum',
            'https://app.abhofladen.at/cookie_policy.html': 'Cookie-Richtlinie',
            'https://app.abhofladen.at/privacy_policy.html': 'Datenschutzerklärung'
        }
    }
}
