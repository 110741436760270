module.exports = {
    future: {
        removeDeprecatedGapUtilities: true,
        purgeLayersByDefault: true
    },
    purge: [
        './src/**/*.vue'
    ],
    theme: {
        extend: {
            spacing: {
                '7.5': '1.875rem',   // 30px
                '12.5': '3.125rem',  // 50px
                15: '3.75rem',       // 60px
                '17.5': '4.375rem',  // 70px
                25: '6.25rem',       // 100px
                30: '7.5rem',        // 120px
                '37.5': '9.375rem',  // 150px

            },
            fontSize: {
                'small': '0.9rem',
                'h1': '2.5rem',
                'h2': '1.6rem',
                'h3': '1.1rem'
            },
            lineHeight: {
                '50px': '3.13rem',
            },
            opacity: {
                30: '0.3',
                85: '0.85'
            }
        },
        screens: {
            xs: '375px',
            sm: '590px',
            xmd: '710px',
            md: '940px',
            lg: '1024px'
        },
        maxWidth: {
            app: '1277px'
        },
        colors: {
            green: '#97a82e',
            gray: '#46535b',
            gray2: '#b1b1b1',
            'gray-light': '#e1e1e1',
            'gray-lighter': '#eee',
            white: '#fff',
            black: '#000',
            transparent: 'transparent'
        },
        fontFamily: {
            sans: 'Montserrat, sans-serif',
            serif: 'Droid Serif, serif'
        },

    },
    variants: {
        borderWidth: ['responsive', 'last'],
    },
    plugins: [],
}
