
































































import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { OpeningHours as OpeningHoursModel } from "abhof-internal-api";
// eslint-disable-next-line no-unused-vars
import { openingHoursService, Weekday } from "@/services/opening-hours-service";

@Component
export default class OpeningHours extends Vue {
    @Prop()
    private apiOpeningHours!: OpeningHoursModel;
    private weekdays!: Weekday[];

    private created(): void {
        this.weekdays = openingHoursService.getHoursItemData(
            this.apiOpeningHours.items!
        );
    }
}
