/**
 * AbHof API Documentation
 * Relaunched AbHof API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Image } from './Image';
import { HttpFile } from '../http/http';

export class AllezamProduct {
    'id'?: string;
    'name'?: string;
    /**
    * Gross Price in Euro Cent
    */
    'grossPrice'?: number;
    'image'?: Image;
    /**
    * If this is a shop with partner status, the URL will point to the product in the partner's own shop. It will otherwise be NULL for the time being, but will point to the product in the abhof marketplace once we have reached that phase of the project.
    */
    'url'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "grossPrice",
            "baseName": "gross_price",
            "type": "number",
            "format": ""
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "Image",
            "format": ""
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string",
            "format": "url"
        }    ];

    static getAttributeTypeMap() {
        return AllezamProduct.attributeTypeMap;
    }
    
    public constructor() {
    }
}

