/**
 * AbHof API Documentation
 * Relaunched AbHof API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Image } from './Image';
import { HttpFile } from '../http/http';

export class VendorComment {
    'id'?: number;
    'title'?: string;
    'comment'?: string;
    'author'?: string;
    'profileImage'?: Image;
    'createdAt'?: string;
    'images'?: Array<Image>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "comment",
            "baseName": "comment",
            "type": "string",
            "format": ""
        },
        {
            "name": "author",
            "baseName": "author",
            "type": "string",
            "format": ""
        },
        {
            "name": "profileImage",
            "baseName": "profile_image",
            "type": "Image",
            "format": ""
        },
        {
            "name": "createdAt",
            "baseName": "created_at",
            "type": "string",
            "format": "datetime"
        },
        {
            "name": "images",
            "baseName": "images",
            "type": "Array<Image>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return VendorComment.attributeTypeMap;
    }
    
    public constructor() {
    }
}

