














import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Section extends Vue {
    @Prop()
    private title!: string;
    @Prop({default: false})
    private isMain!: boolean
    @Prop({default: true})
    private hasBorder!: boolean
}
