































import {Component, Prop, Vue} from 'vue-property-decorator';
import emailImage from '@/assets/images/contact/email.png';
import phoneImage from '@/assets/images/contact/phone.png';
import fbImage from '@/assets/images/contact/fb.png';
import twitterImage from '@/assets/images/contact/twitter.png';
import instagramImage from '@/assets/images/contact/instagram.png';
import webshopImage from '@/assets/images/contact/webshop.png';
import internetImage from '@/assets/images/contact/internet.png';



@Component
export default class ContactLink extends Vue {
    @Prop()
    private type!: string;
    @Prop()
    private value!: string;

    private typeImages = new Map([
        ['email', emailImage],
        ['phone', phoneImage],
        ['facebookUrl', fbImage],
        ['twitterUrl', twitterImage],
        ['instagramUrl', instagramImage],
        ['webshopUrl', webshopImage],
        ['websiteUrl', internetImage],
    ]);

    private shortTypes = [
        'facebookUrl',
        'twitterUrl',
        'instagramUrl'
    ];

    get link(): string
    {
        switch (this.type) {
            case 'phone':
                return `tel:${this.value}`;
            case 'email':
                return `mailto:${this.value}`;
            default:
                return this.value;
        }
    }

    get text(): string
    {
        switch (this.type) {
            case 'webshopUrl':
                return 'Zum Online Shop';
            case 'websiteUrl':
                return this.value
                    .replace(/.*\/\//, '') // remove protocol
                    .replace(/\/$/, ''); // remove trailing slash
            default:
                return this.value;
        }
    }

    get imageUrl(): string
    {
        return this.typeImages.get(this.type);
    }

    get isShort(): boolean
    {
        return this.shortTypes.indexOf(this.type) > -1;
    }
}
